import moment from "moment";

export const defaultParamsFilter = {
    beforeExpiration: 10,
    beforeExpirationCondition: '<=',
    premiumСondition: '>=',
    signalWorkCheck: false,
    premiumLimit: 100000,
    contractDetails: ['OTM'],
    selectedSymbol: 'main',
    selectedType: ['SWEEP'],

    map_selectedType: ['SWEEP'],
    map_moneyTypes: ['OTM'],
    map_premiumCondition: '>=',
    map_premiumLimit: 100000,
    map_beforeExpiration: 10,
    map_beforeExpirationCondition: '<=',

    mainChartDaysPeriod: [],

    selectedProjectNames: [],
    selectedProjectName: 'default',
    
    callsTablePageSize: 10,
    processTablePageSize: 5,

    cpuThreads: 15,
    beamSize: 5,

    showExpired: false,
    timeData: {
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY')
        },
        from: moment().startOf("day"),
        to: moment().endOf("day"),
        unix: {
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix()
        },
        from_to: [
            moment().startOf("day").format('YYYY-MM-DD'),
            moment().endOf("day").format('YYYY-MM-DD')
        ],
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',

    }
}