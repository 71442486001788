import api from '../../api'
const config = {
    storage_prefix: 'manychat_',
    connect: {
        headers: {
            "Authorization": 'Bearer 556229:b47319d5b270d1f198ff22a950223340',
            'accept': 'application/json',
            //"Content-Type": "application/json",
            //accept: '*/*',

            //'sec-fetch-dest': 'empty',
            //'sec-fetch-mode': 'cors', //and type='SWEEP'
        }
    }
}

// user
export function getUserIds(limiter) {
    if (limiter) limiter = limiter;
    else limiter = '';
    var url = "https://manychat.com/fb556229/subscribers/search?limiter=" + limiter;
    var headers = {
        accept: '*/*',
        "content-type": "application/json",
        "cookie": "__atuvc=1%7C46; __atssc=google%3B1; visitorId=eeddd657-896a-481b-b593-be3588ecf838; _fbp=fb.1.1668785584557.1056998095; _hjSessionUser_959213=eyJpZCI6IjA2ZDRhMjYyLTgwNTctNTNiYS04OWM5LWIwMTIwMThkNDk5MCIsImNyZWF0ZWQiOjE2Njg3ODU1ODQ3OTUsImV4aXN0aW5nIjp0cnVlfQ==; mchtClientId=eeddd657-896a-481b-b593-be3588ecf838; mchtca=!; intercom-device-id-bt6uj1lk=99f5873f-fa91-47c6-a057-35b6f30cca9b; __ddg1_=kNI6FWyHkUuYS4clQDyR; _hjSessionUser_2533088=eyJpZCI6IjM0YmYzNTFmLTI4MjktNTk1NS1iYmM2LTJmMTBiMTc1ZmU3NyIsImNyZWF0ZWQiOjE2Njg5NzEzNzM3MjMsImV4aXN0aW5nIjp0cnVlfQ==; clientId=21aad461-b4ac-4eb6-9777-e5facab13bd5; _ga=GA1.2.607032946.1668785584; _ga_4YBGZZJ0F7=GS1.1.1671720448.1.1.1671720500.0.0.0; mc_old_accounts_questionnaire_show_counter-554769=2; mc_old_accounts_questionnaire_show_counter-556229=1; NPS_2c18fff6_last_seen=1676563231959; _gcl_au=1.1.823244859.1676563234; NPS_2c18fff6_surveyed=true; mc_production-main=8cjgh7ggde5qicdrenphptjnjm; _gid=GA1.2.938977034.1679056010; _hjSession_959213=eyJpZCI6IjI1ODIzY2VjLTY5MWUtNDQ1OS05M2NhLWY2OWUzNzk4MzllMCIsImNyZWF0ZWQiOjE2Nzk0MDEzOTQzNzUsImluU2FtcGxlIjpmYWxzZX0=; _hjAbsoluteSessionInProgress=0; _gat_UA-73011234-8=1; _gat_UA-73011234-9=1; _gat=1; _hjIncludedInPageviewSample=1; _hjIncludedInSessionSample_959213=1; intercom-session-bt6uj1lk=M3lnVUtLT1Rwc0twb0JQOXVaV1FmL1E4TkJscjMzcm14TnJCTHVuVlBlREh2S2RpRHlKUUtSQ3Q4bkZGVnM1dS0tdnJweHZTdkdQcTBOdWsrMU9uc3hpQT09--6550ccd0be9a5066c79b080c5c038a3bb4559164"
    };
    var data = { "q": "" };
    const r = api.urlFetch.urlAxios({
        url: url,
        headers: headers,
        method: 'post',
        body: JSON.stringify(data)
    })
    return r
}

export function getUsersIdsFromClickHouse({ ...args }) {

    let its_paid_str = ''
    if (args.its_paid) {
        //console.log(args.its_paid)
        its_paid_str = ` where its_paid = 1 `
    }

    let its_bro_str = ''
    if (args.its_bro) {
        if (args.its_paid) {
            its_bro_str = ` or tag_bro = 1 `
        } else {
            its_bro_str = ` where tag_bro = 1 `
        }
    } else {
        if (args.its_paid) {
            its_bro_str = ` and tag_bro = 0 `
        } else {
            //its_bro_str = ` where tag_bro = 0 `
        }
    }

    const data = `select id user_id 
    from (select 
        *,
        case when tag_1_month = 1  or tag_3_month = 1 or tag_6_month = 1 or tag_12_month = 1 
             then 1 else 0 end as its_paid
        from ManyChat.info
        ${its_paid_str}
        ${its_bro_str}
    )
    
    `;


    //console.log(data)
    const r = api.urlFetch.urlAxiosCH({
        method: 'post',
        body: data
    })

    return r
}

export function getInfosFromClickHouse() {
    const data = `select * from ManyChat.info`;
    const r = api.urlFetch.urlAxiosCH({
        method: 'post',
        body: data
    })
    return r
}

export function getInfoBySubscriberId(subscriber_id) {
    const url = "https://api.manychat.com/fb/subscriber/getInfo?subscriber_id=" + subscriber_id;
    console.log(url)
    const headers = config.connect.headers
    const r = api.urlFetch.urlAxios({
        method: 'get',
        url: url,
        headers: headers
    })
    return r
}


export function getInfoBySubscriberIdFromCH(subscriber_id) {
    const data = `select * from ManyChat.info where id = '${subscriber_id}' `;
    const r = api.urlFetch.urlAxiosCH({
        method: 'post',
        body: data
    })
    return r
}

// storage
export function saveUsersIdsToStorage(data) {
    api.localStore.saveToLocalStorage(config.storage_prefix + 'users_ids', JSON.stringify(data))

}