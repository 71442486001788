import React, { useEffect, useState, useRef } from 'react';
import { Link, Outlet, Routes, Route, useNavigate } from 'react-router-dom'
import { Col, Layout, Menu, Row, Card, Statistic, Button, Input, Tabs, Progress, Checkbox } from "antd";
import { Table } from 'antd';
import moment from "moment-timezone";
import AntDateSelectOptions from '../../components/AntDateSelectOptions';
import CustomRangePicker from '../../components/CustomRangePicker';
import * as Dates from '../../api/DatesOld'
import * as MAIN_CONFIG from '../../MainConfig'
import * as CONFIG from './config'
import * as QUERYS from './querys'
import * as COLUMNS from './columns'
import { URLs } from '../../api/Urls';
import { Select, Tag } from 'antd';
import { InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { Responsive, WidthProvider } from "react-grid-layout";
import './index.css';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import axios from "axios";
import AlaSQL from 'alasql';
import { Avatar, List } from 'antd';
import api from '../../api'

const { TextArea } = Input;
const data = Array.from({
    length: 23,
}).map((_, i) => ({
    href: 'https://ant.design',
    title: `ant design part ${i}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description:
        'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content:
        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));
const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);
const ResponsiveGridLayout = WidthProvider(Responsive);

const defaultDateFormat = 'DD-MM-YYYY';
const { Header, Content, Sider } = Layout;

const project_names = [
    {
        value: 'geometry',
        color: 'gold'
    },
    {
        value: 'tutgood',
        сolor: 'lime',
    },
    {
        value: 'likecenter',
        color: 'blue'
    },
];




const layout = [
    { "w": 10, "h": 8, "x": 2, "y": 0, "i": "blue-eyes-dragon", "moved": false, "static": false, "resizeHandles": ["s", "w", "e", "n", "sw", "nw", "se", "ne"] },
    { "w": 2, "h": 8, "x": 0, "y": 0, "i": "spell-caster", "moved": false, "static": false, "resizeHandles": ["s", "w", "e", "n", "sw", "nw", "se", "ne"] },
    { "w": 12, "h": 50, "x": 0, "y": 8, "i": "summoned-skull", "moved": false, "static": false, "resizeHandles": ["s", "w", "e", "n", "sw", "nw", "se", "ne"] }
]
const layoud_id = "aiwin_dashboard_grid_layout"

const GridItemWrapper = styled.div`
    background: #f5f5f5;
  `;

const GridItemContent = styled.div`
    padding: 8px;
  `;

const Root = styled.div`
    padding: 16px;
  `;
const getLayouts = () => {
    const savedLayouts = localStorage.getItem(layoud_id);
    return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const color = {
        'geometry': 'gold',
        'tutgood': 'blue',
        'likecenter': 'lime'
    }
    return (
        <Tag
            color={color[value]}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};
const pagePosition = 'aiwin_dashboard'

const Dashboard = () => {

    const [StatisticsCallsTotalTime, setStatisticsCallsTotalTime] = useState(null)
    const [StatisticsCallsLen, setStatisticsCallsLen] = useState(0)

    //const [gridLayout, setGridLayout] = useState([{ i: 'a', x: 0, y: 0, w: 1, h: 1 }]);
    const contentTableCallsRef = useRef(null);
    useEffect(() => {
        if (contentTableCallsRef.current) {
            const height = contentTableCallsRef.current.clientHeight;
            // Предполагается, что высота одной ячейки в сетке равна 30 пикселям, 
            // но это значение вы можете настроить в соответствии с вашими потребностями
            const gridHeight = Math.ceil(height / 30);
            console.log(gridHeight)
            //layou
            // setGridLayout([{ i: 'summoned-skull', x: 0, y: 0, w: 1, h: gridHeight }]);
        }
    }, [contentTableCallsRef]);


    const [ParamsFilter, setParamsFilter] = useState(
        JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
            : CONFIG.defaultParamsFilter
    )
    //console.log(ParamsFilter)
    const [RangePickerNewValue, setRangePickerNewValue] = useState(null)
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(Dates.initDateRange())

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [CallData, setCallData] = useState(null)
    const onSelectTableCallsRow = (e) => {
        console.log(e)
        setCallData(e)
        //let _call_data = CallData
        getCallExecData(e)
    }



    const [TableCallsData, setTableCallsData] = useState(null)

    const onClickButtonReload = () => {
        getCallsData({ paramsFilter: ParamsFilter })
    }




    function getCallsData(props) {
        //setMainTableSwitchShowExpiredDisabled(true)
        //setMainTableSwitchShowSentimentDisabled(true)
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getCallsData(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                console.log(resp)
                if (!resp.data) {
                    console.error('!resp.data')
                } else {
                    if (resp.data.length == 0) {
                        console.log('resp.data.length==0')
                        setTableCallsData([])
                    } else {

                        const data = resp.data

                        setTableCallsData(resp.data)
                        //console.log(data)

                        const totals = AlaSQL('select sum(crm_audio_duration) crm_audio_duration from ? ', [data])
                        const calls_len = data.length
                        setStatisticsCallsLen(calls_len)

                        const totalSum = data.reduce((sum, item) => sum + Number(item.crm_audio_duration), 0);
                        console.log(totalSum); // Выведет: 60
                        const _statistics_calls_total_time = api.Times.secondsToTimeFormat(totalSum, 1)
                        setStatisticsCallsTotalTime(_statistics_calls_total_time)

                        console.log(totals)
                    }
                }
            })
    }


    const onChangeRangePicker = (value) => {
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const date_from = moment(value[0].$d).format(dateFormat)
        const date_to = moment(value[1].$d).format(dateFormat)
        let time_from = date_from + ' 00:00:00'
        let time_to = date_to + ' 23:59:59'
        let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
        let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
        paramsFilter.timeData = {
            from: date_from,
            to: date_to,
            value: 'fixed',
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: date_from,
                date_to: date_to
            }
        }
        if (DateSelectOptionsValue === 'fixed') {
            setRangePickerNewValue({
                from: date_from,
                to: date_to
            })

        } else {
            setDateSelectOptionsValue('fixed')
        }

        getCallsData({ paramsFilter: paramsFilter })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))

    }


    const onChangeAntDateSelectOptions = (e) => {
        const pickerRangeValue = DateSelectOptionsRaw[e]
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const time_from = pickerRangeValue.work.date_from + ' 00:00:00'
        const time_to = pickerRangeValue.work.date_to + ' 23:59:59'
        setDateSelectOptionsValue(e)
        if (e === 'today') {
            //setSignalWorkCheck(true)
            paramsFilter.signalWorkCheck = true
        } else {
            //setSignalWorkCheck(false)
            paramsFilter.signalWorkCheck = false
        }
        if (e === 'fixed') {
            paramsFilter.timeData = pickerRangeValue
        } else {
            const mFromTo = [
                moment(moment(pickerRangeValue.work.date_from), defaultDateFormat),
                moment(moment(pickerRangeValue.work.date_to), defaultDateFormat)
            ]
            const newDateFrom = moment(pickerRangeValue.work.date_from).format(dateFormat)
            const newDateTo = moment(pickerRangeValue.work.date_to).format(dateFormat)
            setRangePickerNewValue({
                from: newDateFrom,
                to: newDateTo
            })
            let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
            let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
            let timeData = {
                from: newDateFrom,
                to: newDateTo,
                //from_to: mFromTo,
                value: e,
                work: pickerRangeValue.work,
                visual: pickerRangeValue.visual,
                unix: {
                    from: unix_from,
                    to: unix_to
                }
            }
            paramsFilter.timeData = timeData
            //updateDataNew({ paramsFilter: paramsFilter, settingsMain: SettingsMain })
        }

        //console.log('from onChangeAntDateSelectOptions ')
        //console.log(JSON.stringify(paramsFilter))

        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
        setParamsFilter(paramsFilter)
        getCallsData({ paramsFilter: paramsFilter })
    }

    const onChangeProjectNames = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedProjectNames = e
        setParamsFilter(paramsFilter)
        getCallsData({ paramsFilter: paramsFilter })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }



    const [TableCallsPageSize, setTableCallsPageSize] = useState(ParamsFilter.callsTablePageSize ? ParamsFilter.callsTablePageSize : 10)
    const [UbuntuWhisperCpuThreads, setUbuntuWhisperCpuThreads] = useState(ParamsFilter.cpuThreads ? ParamsFilter.cpuThreads : 15)
    const [UbuntuWhisperBeamSize, setUbuntuWhisperBeamSize] = useState(ParamsFilter.beamSize ? ParamsFilter.beamSize : 5)
    const onChangePageSize = (value) => {
        console.log('changed', value);
        let paramsFilter = ParamsFilter
        paramsFilter.callsTablePageSize = value
        setParamsFilter(paramsFilter)
        setTableCallsPageSize(value)
        //getCallsData({ paramsFilter: paramsFilter })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    };



    const handleLayoutChange = (layout, layouts) => {
        localStorage.setItem(layoud_id, JSON.stringify(layouts));
    };
    const [TranscribatorMode, setTranscribatorMode] = useState('dev')
    const onChangeTranscribatorMode = (value) => {
        let _call_data = CallData
        _call_data.mode = value
        setTranscribatorMode(value)
        setCallData(CallData)
        //console.log(value)
        //RunTranscribator()
    };
    const [Transcribator, setTranscribator] = useState('ubuntu-whisper')
    const [OperationId, setOperationId] = useState(null)
    const onChangeTranscribator = (value) => {
        setTranscribator(value)
        console.log(value)
        //RunTranscribator()
    }


    const onClickButtonRunTranscribator = () => {
        console.log("onClickButtonRunTranscribator")
        async function RunTranscribator(CallData) {
            if (CallData) {
                if (CallData.cid) {
                    CallData.cpu_threads = UbuntuWhisperCpuThreads
                    CallData.beam_size = UbuntuWhisperBeamSize

                    console.log(JSON.stringify(CallData))

                    await _run_transcribator(CallData)


                }
            }
            //console.log(t)
        }
        RunTranscribator(CallData)
    }




    const onSelectTableProcessRow = (e) => {
        console.log(e)
        //setCallData(e)
        //let _call_data = CallData
        //getCallExecData(e)
        _get_result(e.operation_id)
    }
    const [TableProcessPageSize, setTableProcessPageSize] = useState(ParamsFilter.precessTablePageSize ? ParamsFilter.processTablePageSize : 10)
    const onChangeProcessPageSize = (value) => {
        //console.log('changed', value);
        let paramsFilter = ParamsFilter
        paramsFilter.processTablePageSize = value
        setParamsFilter(paramsFilter)
        setTableProcessPageSize(value)
        //getCallsData({ paramsFilter: paramsFilter })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    };
    const onClickButtonReloadProcess = () => {
        getCallExecData(CallData)
    }
    const [ListExecData, setListExecData] = useState()
    const [TableExecData, setTableExecData] = useState()
    function getCallExecData(CallData) {
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getCallExecData(CallData)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                console.log(resp)
                if (!resp.data) {
                    console.error('!resp.data')
                } else {
                    if (resp.data.length == 0) {
                        console.log('resp.data.length==0')
                        setTableExecData([])
                    } else {
                        setListExecData(resp.data)
                        setTableExecData(resp.data)
                        //setTableCallsData(resp.data)

                    }
                }
            })

    }
    const [OperationSelectedStatus, setOperationSelectedStatus] = useState(null); // Состояние статуса
    const [OperationSelectedTimerId, setOperationSelectedTimerId] = useState(null); // Идентификатор таймера
    const [OperationSelectedAudioDownload, setOperationSelectedAudioDownload] = useState(null);
    const [OperationSelectedProcessStatus, setOperationSelectedProcessStatus] = useState(null);


    const [ProcessData, setProcessData] = useState()

    useEffect(() => {
        if (OperationSelectedStatus !== "ok" && OperationSelectedStatus !== "error") {
            //startOperationSelectedTimer(OperationId); // Запускаем таймер при монтировании компонента
        } else {
            //clearInterval(OperationSelectedTimerId); // Очищаем таймер, когда статус получен
        }

        return () => {
            //clearInterval(OperationSelectedTimerId); // Очищаем таймер при размонтировании компонента
        };
    }, [OperationSelectedStatus, OperationSelectedTimerId]);

    async function _get_result(operation_id) {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer YBmsChmP8t7j0GtK7szA7U7s79"
        }
        try {
            const statusResponse = await fetch(`https://api.aiwin.app/api/method/getResult/${operation_id}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ operation_id: operation_id })
            })
            const statusData = await statusResponse.json();


            setProcessData(statusData)
            let transcribe_percent = statusData.transcribe_percent ? Number(statusData.transcribe_percent).toFixed(0) : 0
            setOperationSelectedProgress(transcribe_percent)
            setOperationSelectedProcessStatus(statusData.status)
            setOperationSelectedAudioDownload(statusData.audio_download)
            setOperationSelectedTranscript(statusData.transcript)

            let audio_duration = statusData.audio_duration
            setOperationSelectedAudioDuration(audio_duration)

            const audio_now_transcribed = statusData.audio_now_transcribed ? Number(statusData.audio_now_transcribed).toFixed(2) : 0
            setOperationSelectedAudioNowTranscribed(audio_now_transcribed)

            const delta = statusData.delta ? Number(statusData.delta).toFixed(2) : 0
            setOperationSelectedDelta(delta)

            if (statusData.status === 'ok' || statusData.status === 'error') {
                //clearInterval(interval);
                //setProcessing(false);
            }
            console.log(statusData)
            let _call_data = CallData
            _call_data.cid = statusData.cid
            _call_data.operation_id = statusData.operation_id
            setCallData(_call_data)
            setOperationId(operation_id)

        } catch (error) {
            console.error('Произошла ошибка:', error);
            //clearInterval(interval);
            //setProcessing(false);
        }
    }

    async function _reload_result(operation_id) {
        const url = `https://api.aiwin.app/api/method/getResult/${operation_id}`;
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer YBmsChmP8t7j0GtK7szA7U7s79"
        }
        try {
            fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    operation_id: operation_id
                })
            }).then((resp_json) => resp_json.json())
                .then((resp) => {
                    console.log(resp)
                    setOperationSelectedStatus(resp.status);

                    //getCallExecData(CallData)
                })
        } catch (e) {
            console.error(e)
        }
    }

    const onClickButtonReloadResult = () => {
        console.log('onClickButtonReloadResult')
        async function ReloadResult(OperationId) {
            await _reload_result(OperationId)
        }
        ReloadResult(OperationId)
    }

    const [processing, setProcessing] = useState(false);
    const [OperationSelectedProgress, setOperationSelectedProgress] = useState(0)
    const [OperationSelectedTranscript, setOperationSelectedTranscript] = useState()
    const [OperationSelectedAudioDuration, setOperationSelectedAudioDuration] = useState(0)
    const [OperationSelectedAudioNowTranscribed, setOperationSelectedAudioNowTranscribed] = useState(0)
    const [OperationSelectedDelta, setOperationSelectedDelta] = useState(0)

    async function _run_transcribator(CallData) {
        setProcessing(true);
        setOperationSelectedProgress(0)
        const url = "https://api.aiwin.app/api/method/transcribe";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer YBmsChmP8t7j0GtK7szA7U7s79"
        }
        try {
            fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(CallData)
            }).then((resp_json) => resp_json.json())
                .then((resp) => {
                    console.log(resp)
                    let operation_id = resp.operation_id
                    if (operation_id) {
                        setOperationId(operation_id)
                        //getCallExecData(CallData)
                        //fetchData(operation_id)
                        //_reload_result(operation_id)


                        try {

                            const interval = setInterval(async () => {
                                try {
                                    const statusResponse = await fetch(`https://api.aiwin.app/api/method/getResult/${operation_id}`, {
                                        method: 'POST',
                                        headers: headers,
                                        body: JSON.stringify({ operation_id: operation_id })
                                    })
                                    const statusData = await statusResponse.json();



                                    let transcribe_percent = statusData.transcribe_percent ? Number(statusData.transcribe_percent).toFixed(0) : 0
                                    setOperationSelectedProgress(transcribe_percent)
                                    setOperationSelectedProcessStatus(statusData.status)
                                    setOperationSelectedAudioDownload(statusData.audio_download)
                                    setOperationSelectedTranscript(statusData.transcript)

                                    let audio_duration = statusData.audio_duration
                                    setOperationSelectedAudioDuration(audio_duration)

                                    const audio_now_transcribed = statusData.audio_now_transcribed ? Number(statusData.audio_now_transcribed).toFixed(2) : 0
                                    setOperationSelectedAudioNowTranscribed(audio_now_transcribed)

                                    const delta = statusData.delta ? Number(statusData.delta).toFixed(2) : 0
                                    setOperationSelectedDelta(delta)

                                    if (statusData.status === 'ok' || statusData.status === 'error') {
                                        clearInterval(interval);
                                        setProcessing(false);
                                    }
                                    console.log(statusData)

                                } catch (error) {
                                    console.error('Произошла ошибка:', error);
                                    clearInterval(interval);
                                    setProcessing(false);
                                }
                            }, 10000);
                        } catch (error) {
                            console.error('Произошла ошибка:', error);
                            setProcessing(false);
                        }

                    }
                })
                .catch((error) => {
                    console.error('Произошла ошибка:', error);
                    setProcessing(false); // Обновляем состояние
                });
        } catch (e) {
            console.error(e)
        }


    }

    const onChangeCheckbox = (e) => {
        console.log(`checked = ${e.target.checked}`);
        let _call_data = CallData
        _call_data.convertmp4 = e.target.checked
        setCallData(_call_data)
    };

    const onClickButtonSendToBpium = () => {

        let project = '9' //likecenter

        let _process_data = ProcessData

        let utm = {}
        let crm = _process_data.crm

        let response_data = {
            "project": project,
            "operation_id": _process_data.operation_id,
            "transcript": _process_data.transcript,
            "utm": utm,
            "crm": crm,
            "audio": {
                "url": '',
                "filename": '',
                "size": _process_data.audio_size,
                "duration": _process_data.audio_duration
            }
        }

        console.log(response_data)
        //console.log(JSON.stringify(response_data))

        const url = `https://api.aiwin.app/api/method/sendTranscriptToBpium`;
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer YBmsChmP8t7j0GtK7szA7U7s79"
        }
        try {
            fetch(url, {
                //mode: 'no-cors',
                method: 'POST',
                headers: headers,
                body: JSON.stringify(response_data)
            }).then((resp_json) => resp_json.json())
                .then((resp) => {
                    console.log(resp)


                    //getCallExecData(CallData)
                })
        } catch (e) {
            console.error(e)
        }

        // console.log('onClickButtonSendToBpium')
        // options = {
        //     "method": 'post',
        //     "url": f'https://islamwatch.bpium.ru/api/webrequest/AsyncTranscript?project={project}',
        //     "payload": payload,
        //     "headers": {
        //         'Content-Type': 'application/json'
        //     }
        // }
    }

    return (<>

        <Layout style={{ background: 'gray', height: 1500 }} className="react-grid-layout">
            <Root>
                <ResponsiveGridLayout
                    layouts={getLayouts()}
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={10}
                    width={10}

                    resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
                    onLayoutChange={handleLayoutChange}
                >
                    <GridItemWrapper key="blue-eyes-dragon">
                        <GridItemContent>Фильтры

                            <Card size='small'>
                                <Row>
                                    <Col span={12}>
                                        <CustomRangePicker
                                            defaultFrom={ParamsFilter.timeData.work.date_from}
                                            defaultTo={ParamsFilter.timeData.work.date_to}
                                            valueFrom={RangePickerNewValue ? (
                                                RangePickerNewValue.from ? RangePickerNewValue.from :
                                                    ParamsFilter.timeData.work.date_from
                                            ) : ParamsFilter.timeData.work.date_from}
                                            valueTo={RangePickerNewValue ? (
                                                RangePickerNewValue.to ? RangePickerNewValue.to :
                                                    ParamsFilter.timeData.work.date_to
                                            ) : ParamsFilter.timeData.work.date_to}
                                            onChange={onChangeRangePicker}
                                        ></CustomRangePicker>
                                    </Col>

                                    <Col span={12}>
                                        <AntDateSelectOptions
                                            defaultValue={ParamsFilter.timeData.value}
                                            options={DateSelectOptionsRaw}
                                            onChange={onChangeAntDateSelectOptions}
                                            value={DateSelectOptionsValue}
                                        ></AntDateSelectOptions>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        Проект
                                    </Col>
                                    <Col span={12}>
                                        <Select
                                            mode="multiple"
                                            tagRender={tagRender}
                                            defaultValue={[]}
                                            style={{
                                                width: '100%',
                                            }}
                                            value={ParamsFilter.selectedProjectNames ? ParamsFilter.selectedProjectNames : []}
                                            options={project_names}
                                            onChange={onChangeProjectNames}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </GridItemContent>
                    </GridItemWrapper>




                    <GridItemWrapper key="spell-caster">
                        <GridItemContent>Сводные

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title={MAIN_CONFIG.main_strings.dashboard.ru.statistics_calls_total_time}
                                            value={StatisticsCallsTotalTime}
                                            valueStyle={{
                                                color: '#cf1322',
                                            }}
                                        />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title={MAIN_CONFIG.main_strings.dashboard.ru.statistics_calls_len}
                                            value={StatisticsCallsLen}
                                            valueStyle={{
                                                color: '#cf1322',
                                            }}
                                        />
                                    </Card>
                                </Col>
                            </Row>


                        </GridItemContent>
                    </GridItemWrapper>

                    <GridItemWrapper key="summoned-skull">
                        <GridItemContent>Звонки

                            <Card key="summoned-skull" ref={contentTableCallsRef}>
                                <Row>
                                    Звонков на странице
                                    <InputNumber
                                        defaultValue={TableCallsPageSize}
                                        value={TableCallsPageSize}
                                        onChange={onChangePageSize}
                                    />

                                    <Button onClick={onClickButtonReload}>Обновить</Button>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Table
                                            size='small'
                                            columns={COLUMNS.tableCalls}
                                            dataSource={TableCallsData}
                                            onChange={onChange}
                                            //onSelect={onSelectTableCallsRow}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    //onClick: (event) => { onSelectTableCallsRow(record) }, // click row
                                                    //onDoubleClick: (event) => { }, // double click row
                                                    //onContextMenu: (event) => { }, // right button click row
                                                    //onMouseEnter: (event) => { }, // mouse enter row
                                                    //onMouseLeave: (event) => { }, // mouse leave row
                                                };
                                            }}
                                            pagination={{
                                                pageSize: ParamsFilter.callsTablePageSize ? ParamsFilter.callsTablePageSize : 10,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Card>

                        </GridItemContent>
                    </GridItemWrapper>


                </ResponsiveGridLayout>
            </Root>
        </Layout>






        <Outlet />
    </>)
}

export default Dashboard