export function fenceArr2(array) {
    let out = ''
    array.forEach((element, i) => {
        out = out + `'${element}' ,`
    });
    out = `( ${out} )`
    let out2 = out.replace(', )', ' )')
    //console.log(out)
    return out2
}

export function TableOnDays_GetQuery(props) {


    const unix_from = props.paramsFilter.timeData.unix.from*1000
    const unix_to = props.paramsFilter.timeData.unix.to*1000

    const selected_project_names = props.paramsFilter.selectedProjectNames
    let selected_project_names_str = ''
    if (selected_project_names) {
        if (selected_project_names.length > 0) {
            selected_project_names_str = `and project_name in  ${fenceArr2(selected_project_names)}  `
        } else {
            const defaultProjects = (props.userData.projects).map(obj => obj.value);
            selected_project_names_str = `and project_name in  ${fenceArr2(defaultProjects)}  `
        }
    } else {
        const defaultProjects = (props.userData.projects).map(obj => obj.value);
        selected_project_names_str = `and project_name in  ${fenceArr2(defaultProjects)}  `
    }
    
    const query =

    `


        select
    
        cid, crm_call_date, crm_audio_duration, transcribe_permission, transcribe_reason 
       
       
       from (select * from (
           select cid as key, *
          
           from calls
           where crm_call_date >= ${unix_from} and crm_call_date <= ${unix_to}
           ${selected_project_names_str}
           ) c 
           left join 
           ( select distinct cid, transcribe_permission, transcribe_reason from executions where func_name = 'gaps-precheck') e
           on c.cid = e.cid
           )
           
           order by crm_call_date desc
    `

    //console.log(query)
    return query

}

export function TableOnOperators_GetQuery(props) {

    const unix_from = props.paramsFilter.timeData.unix.from//*1000
    const unix_to = props.paramsFilter.timeData.unix.to//*1000

    const selected_project_names = props.paramsFilter.selectedProjectNames
    let selected_project_names_str = ''
    if (selected_project_names) {
        if (selected_project_names.length > 0) {
            selected_project_names_str = `and project_name in  ${fenceArr2(selected_project_names)}  `
        } else {
            const defaultProjects = (props.userData.projects).map(obj => obj.value);
            selected_project_names_str = `and project_name in  ${fenceArr2(defaultProjects)}  `
        }
    } else {
        const defaultProjects = (props.userData.projects).map(obj => obj.value);
        selected_project_names_str = `and project_name in  ${fenceArr2(defaultProjects)}  `
    }
    
    const query =    
      ` 
select * from (
        select * from (select cid, Bot as bot, bid, json_analyze,  toUnixTimestamp(toDateTime(DateCreated)) analysis_unix from Analysis2 where cid not in ( '', '123' ) ) a
left join calls c on a.cid = c.cid
where c.cid != '') a

where analysis_unix >= ${unix_from} and analysis_unix <= ${unix_to}
        ${selected_project_names_str}

        order by analysis_unix desc

 
            
    `

    //console.log(query)
    return query

}