import React from 'react';
import { signInWithGoogle } from "../../services/firebase";
import { GoogleOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import './LoginPage.css';
import * as MAIN_CONFIG from '../../MainConfig'

const LoginPage = ({ preloader }) => {
  const language = MAIN_CONFIG.main_strings[MAIN_CONFIG.main_language]

  const wrapperStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0, 
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  };

  if(preloader){
    return (<Spin 
      style={wrapperStyle}
      //spinning={preloader} 
      tip="Loading" size="large" 
      
      >
        
      </Spin>)
  } else 

  return (
    // <Spin spinning={preloader} tip="Loading" size="large" >
      <>
        <div id="app" className="aiwin-app">
          <div>
            <div className="gFGKXf">
              <div className="eJJsmG">
                <div className="aWMLl">

                  <div className="bjuzyG">
                    {language.login_page_str_1}
                  </div>

                  <p class="fiLqzS">
                    {language.login_page_str_2}
                  </p>

                  <div >
                    <Button
                      className="login-form-button"
                      onClick={signInWithGoogle}

                    >
                      <GoogleOutlined />
                      {language.login_page_str_3}
                    </Button>
                  </div>

                </div>
              </div>
            </div>

          </div></div>
      </>
    //</Spin>
  )
}


export default LoginPage;