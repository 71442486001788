import React from "react"
import moment from "moment";
import { Tag } from "antd";
import {
    RiseOutlined, FallOutlined
} from '@ant-design/icons';

const where_timeZone = ` where timeZone !='America/New_York' `

export const defaultMainSettings = {
    type: 'default',
    sessionStart: '09:30',
    sessionTimeStamp: 0,
    selectedSetting: 'time'
}

export const TableTypeOptions = [{
    value: 'SWEEP',
}, {
    value: 'BLOCK',
}, {
    value: 'SPLIT',
}]

export const OptionTypesColor = {
    'SWEEP': 'gold',
    'BLOCK': 'lime',
    'SPLIT': 'green'
}

export const contractDetailsOptions = [
    { value: 'OTM', },
    { value: 'ITM', },
    { value: 'ATM' }
]

export const mainChartDaysPeriodOptions = [
    { value: '7_days', },
    { value: '15_days', },
    { value: '30_days', }
]

export const defaultRange = {
    from: moment().startOf("month").format('yyyy-MM-DD'),
    to: moment().endOf("month").format('yyyy-MM-DD'),
    unix_from: moment().startOf("month").unix(),
    unix_to: moment().endOf("month").unix(),
    dateFormat: 'DD-MM-YYYY'
};

export const defaultTimeData = () => {
    const visual_from = moment().startOf("day").format('DD.MM.YYYY')
    const visual_to = moment().endOf("day").format('DD.MM.YYYY')
    const caption = visual_from + " - " + visual_to;
    return ({
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY'),
            caption: caption
        },
        from: moment().utc().startOf("day"),
        to: moment().utc().endOf("day"),
        unix: {
            from: moment().utc().startOf("day").unix(),
            to: moment().utc().endOf("day").unix()
        },
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',
    })
}


export const defaultParamsFilter = {
    beforeExpiration: 10,
    beforeExpirationCondition: '<=',
    premiumСondition: '>=',
    signalWorkCheck: false,
    premiumLimit: 100000,
    contractDetails: ['OTM'],
    selectedSymbol: 'main',
    selectedType: ['SWEEP'],

    map_selectedType: ['SWEEP'],
    map_moneyTypes: ['OTM'],
    map_premiumCondition: '>=',
    map_premiumLimit: 100000,
    map_beforeExpiration: 10,
    map_beforeExpirationCondition: '<=',

    mainChartDaysPeriod: [],

    selectedProjectNames: [],
    
    callsTablePageSize: 10,
    processTablePageSize: 5,

    cpuThreads: 15,
    beamSize: 5,

    showExpired: false,
    timeData: {
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY')
        },
        from: moment().startOf("day"),
        to: moment().endOf("day"),
        unix: {
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix()
        },
        from_to: [
            moment().startOf("day").format('YYYY-MM-DD'),
            moment().endOf("day").format('YYYY-MM-DD')
        ],
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',

    }
}