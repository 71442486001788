import React from "react"
import { Select } from 'antd'
import * as Dates from '../../api/DatesOld'

const { Option } = Select;

const AntDateSelectOptions = (props) => {

    let options = []
    if (props.global) {
        if (props.global.options) {
            options = Dates.initDateRange()
        } else {
            options = props.options
        }
    } else {
        options = props.options
    }

    const Resp = ({ children }) => { return (<>{children}</>) }
    if (options) {
        return (
            <Resp>
                <Select
                    defaultValue={props.defaultValue}
                    style={{ width: '100%' }}
                    onChange={props.onChange}
                    listHeight={'100%'}
                    value={props.value}
                >
                    {Object.keys(options).map((value, index) => {
                        return (
                            <Option value={options[value].value} key={index}>
                                {options[value].label}
                                <li style={{ fontSize: 12 }}>
                                    {options[value].visual.caption}
                                </li>
                            </Option>
                        );
                    })}
                </Select>
            </Resp >
        )
    } else {
        console.log('AntDateSelectOptions error')
        return (
            <Resp>
                AntDateSelectOptions error
            </Resp>
        )
    }
}

export default AntDateSelectOptions