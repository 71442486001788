import React, { useEffect, useState } from "react"
import { Outlet, useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import PageContainer from "../../components/PageContainer"
import List from "../../components/List"
import { Table } from 'antd';
import * as MAIN_CONFIG from '../../MainConfig'
import api from "../../api";
import DialogContent from "./DialogContent";

const main_strs = MAIN_CONFIG.getMainStrings()

const columns = [
    {
        title: 'Дата',
        dataIndex: 'createdAt',
        width: '30%',
    },
    {
        title: 'ID митинга',
        dataIndex: 'meetingId',
    }
];


const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

const Trancripts = ({ userData }) => {
    const navigate = useNavigate();
    const [transcriptsList, setTranscriptsList] = useState(null);

    const handleRowClick = (record) => {
        console.log('Clicked row:', record);
        const { meetingId } = record
        if (!meetingId) {
            throw new Error('meetingId null')
        }
        navigate(`/app/transcripts/${meetingId}`)
    };

    useEffect(() => {

        async function fetchDataAndProcess(uid) {
            const transcriptData = new api.Aiwin.Transcripts.TranscriptData(uid)
            await transcriptData.init();
            const rawData = transcriptData.getRaw();
            console.log(rawData);
            transcriptData.initSpeakers()
            //const speakers = transcriptData.getSpeakers()
            //console.log(speakers);

            const list = transcriptData.getList()
            setTranscriptsList(list)
            console.log(list)
        }

        fetchDataAndProcess(userData.uid);

    }, []);

    return (
        <>
            <PageContainer
                title={main_strs.transcripts_page_title}
                children={<>

                    <Table
                        columns={columns}
                        dataSource={transcriptsList}
                        onChange={onChange}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
                </>}
            ></PageContainer>
        </>
    )
}

export default Trancripts

/*
<List
                        data={[...userData.transcripts, ...userData.transcripts]}
                    >

                    </List>
                    */