import React from 'react';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'

const CustomRangePicker = ({ defaultFrom, defaultTo, valueFrom, valueTo, onChange }) => {

    const onChangeRangePicker = (e) => {
        onChange(e)
    }
    const _default_value =  [dayjs(defaultFrom, dateFormat), dayjs(defaultTo, dateFormat)]
    //console.log(_default_value)

    return (<>
        <RangePicker
            style={{ width: '100%' }}
            defaultValue={_default_value}
            value={[dayjs(valueFrom, dateFormat), dayjs(valueTo, dateFormat)]}
            format={dateFormat}
            cellRender={(current) => {
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1677ff';
                    style.borderRadius = '50%';
                }
                return (
                    <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                    </div>
                );
            }}
            onChange={(e) => onChangeRangePicker(e)}
        />
    </>)
}

export default CustomRangePicker