import React, { useState } from 'react';
import { Layout, Card, Breadcrumb, Typography, Row, Col, Select } from 'antd';
import * as COLUMNS from '../DashBoardStatic_v2/columns'
import * as CONFIG from './config'

const { Title, Text } = Typography;
const { Content } = Layout;
const pagePosition = 'aiwin_statistics'

const StatisticsPage = ({ userData }) => {
    const [ParamsFilter, setParamsFilter] = useState(
        JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
            : CONFIG.defaultParamsFilter
    )
    const [ProjectNames, setProjectNames] = useState(userData.projects)
    console.log(userData)
    const params = {
        'likecenter': {
            url: "https://datalens.yandex/t5412bh4xwqsi?_embedded=1&_no_controls=1"
            + "&project_name_8za2=likecenter"
        },
        'ldas': {
            url: "https://datalens.yandex/fru0cf91mwgs4?_embedded=1&_no_controls=1"
                + "&project_name_8za2=ldas"
        },
        'tutgood': {
            url: "https://datalens.yandex/ampw0fsig60mz?_embedded=1&_no_controls=1"
            + "&project_name_8za2=tutgood"
        },
        'default': {
            'url': "https://datalens.yandex/1dgm5k7y78pkq"
        }
    }

    const onChangeProjectNames = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedProjectName = e


        // TableOnDays_GetData({ paramsFilter: paramsFilter, userData: userData })
        // TableOnOperators_GetData({ paramsFilter: paramsFilter, userData: userData })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
        setParamsFilter(paramsFilter)
        setFrameSrc(params[e].url)
    }

    const [FrameSrc, setFrameSrc] = useState(
        userData.projects.length > 0 ? params[ParamsFilter.selectedProjectName ? ParamsFilter.selectedProjectName : "default"].url
            : params.default.url
    )

    //console.log(FrameSrc)

    return (<>
        {/* <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>App</Breadcrumb.Item>
            <Breadcrumb.Item>Statistics</Breadcrumb.Item>
        </Breadcrumb>
        <Text style={{
            fontWeight: 'bold',
            fontFamily: 'monospace',
            fontSize: 35,
            margin: 15,
            color: '#2B3674'
        }}>Статистика</Text > */}
        {/* https://datalens.yandex.ru/t5412bh4xwqsi-likecenter-statistics */}

        <Row>

            <Card size='small'
                style={{
                    width: '100%',
                    //margin: 5
                }}
            >
                <Row>
                    <Col span={6}>
                        Проект
                    </Col>
                    <Col span={6}>
                        <Select
                            mode="single"
                            tagRender={COLUMNS.tagRender}
                            defaultValue={[]}
                            style={{
                                width: '100%',
                            }}
                            value={
                                userData.projects.length > 0 ?
                                    (ParamsFilter.selectedProjectName ? ParamsFilter.selectedProjectName : 'default')
                                    : []

                            }
                            options={ProjectNames}
                            onChange={onChangeProjectNames}
                        />
                    </Col>
                    <Col span={6}>

                    </Col>
                    <Col span={6}>

                    </Col>
                </Row>
            </Card>


        </Row>

        <Row style={{ margin: 4 }}></Row>

        <Layout>
            <Content >
                {/* <Card > */}
                <iframe
                    //src="https://datalens.yandex/y6abxhts08iun?_embedded=1&_no_controls=1&project_name_8za2=ldas&6ca74f70-62de-11ee-95db-fb015eec19b7=%D0%94%D0%9F"
                    src={FrameSrc}
                    width="100%"
                    height="1400px"
                    style={{ border: 'none' }}
                    title="statistics"
                />
                {/* </Card> */}
            </Content>
        </Layout>

    </>
    );
}

export default StatisticsPage;