
import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
} from 'react';
import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Link, Outlet, Routes, Route, useNavigate } from 'react-router-dom'
import { Col, Layout, Menu, Row, Card, Statistic, Button, Input, Tabs, Progress, Checkbox } from "antd";
import { Table } from 'antd';
import moment from "moment-timezone";
import AntDateSelectOptions from '../../components/AntDateSelectOptions';
import CustomRangePicker from '../../components/CustomRangePicker';
import * as Dates from '../../api/DatesOld'
import * as MAIN_CONFIG from '../../MainConfig'
import * as CONFIG from './config'
import * as QUERYS from './querys'
import * as COLUMNS from './columns'
import { URLs } from '../../api/Urls';
import { Select, Tag } from 'antd';
import { InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { Responsive, WidthProvider } from "react-grid-layout";

import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import axios from "axios";
import AlaSQL from 'alasql';
import { Avatar, List } from 'antd';
import api from '../../api'

const defaultDateFormat = 'DD-MM-YYYY';
const pagePosition = 'aiwin_dashboard'
const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const color = {
        'geometry': 'gold',
        'tutgood': 'blue',
        'likecenter': 'lime'
    }
    return (
        <Tag
            color={color[value]}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};
const project_names = [
    {
        value: 'geometry',
        color: 'gold'
    },
    {
        value: 'tutgood',
        сolor: 'lime',
    },
    {
        value: 'likecenter',
        color: 'blue'
    },
];
AlaSQL.fn.CHARINDEX = function (substring, string) {
    return string.indexOf(substring) + 1; // JavaScript 'indexOf' возвращает -1 если не найдено, иначе позицию начиная с 0. SQL начинает с 1.
};



const DashboardStatic = ({ userData }) => {
    const smW = 500
    console.log(userData)

    const [ProjectNames, setProjectNames] = useState(userData.projects)

    window.addEventListener('resize', (event) => {
        TableDetailedGradeSizeToFit()
        TableOnOperatorsAutoSizeToFit()
    })

    const [ParamsFilter, setParamsFilter] = useState(
        JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
            : CONFIG.defaultParamsFilter
    )

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();

    // Фильтры основные

    const [RangePickerNewValue, setRangePickerNewValue] = useState(null)
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(Dates.initDateRange())

    // Таблица "По сотрудникам"
    const TableOnOperatorsGridRef = useRef()
    const [TableOnOperatorsRowData, setTableOnOperatorsRowData] = useState();
    const [TableOnOperatorsSelectedOperators, setTableOnOperatorsSelectedOperators] = useState()

    // Таблица "По дням"
    const gridRef = useRef()
    const TableOnDaysRef = useRef()
    const [TableOnDaysRowData, setTableOnDaysRowData] = useState();

    // Таблица оценок
    const TableDetailedGradeRef = useRef()
    const [TableDetailedGradeRowData, setTableDetailedGradeRowData] = useState();

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Athlete Details',
            suppressStickyLabel: true,
            children: [
                { field: 'athlete', pinned: true, colId: 'athlete' },
                { field: 'country', colId: 'country' },
                { field: 'age', colId: 'age' },
            ],
        },
        {
            headerName: 'Sports Results',
            suppressStickyLabel: true,
            openByDefault: true,
            children: [
                { field: 'sport', colId: 'sport' },
                { field: 'gold', colId: 'gold', columnGroupShow: 'open' },
                { field: 'silver', colId: 'silver', columnGroupShow: 'open' },
                { field: 'bronze', colId: 'bronze', columnGroupShow: 'open' },
                { field: 'total', colId: 'total', columnGroupShow: 'closed' },
            ],
        },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            width: 200,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
            .then((resp) => resp.json())
            .then((data) => setRowData(data));
    }, []);



    // Фильтры основные
    const onChangeAntDateSelectOptions = (e) => {
        const pickerRangeValue = DateSelectOptionsRaw[e]
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const time_from = pickerRangeValue.work.date_from + ' 00:00:00'
        const time_to = pickerRangeValue.work.date_to + ' 23:59:59'
        setDateSelectOptionsValue(e)
        if (e === 'today') {
            //setSignalWorkCheck(true)
            paramsFilter.signalWorkCheck = true
        } else {
            //setSignalWorkCheck(false)
            paramsFilter.signalWorkCheck = false
        }
        if (e === 'fixed') {
            paramsFilter.timeData = pickerRangeValue
        } else {
            const mFromTo = [
                moment(moment(pickerRangeValue.work.date_from), defaultDateFormat),
                moment(moment(pickerRangeValue.work.date_to), defaultDateFormat)
            ]
            const newDateFrom = moment(pickerRangeValue.work.date_from).format(dateFormat)
            const newDateTo = moment(pickerRangeValue.work.date_to).format(dateFormat)
            setRangePickerNewValue({
                from: newDateFrom,
                to: newDateTo
            })
            let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
            let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
            let timeData = {
                from: newDateFrom,
                to: newDateTo,
                //from_to: mFromTo,
                value: e,
                work: pickerRangeValue.work,
                visual: pickerRangeValue.visual,
                unix: {
                    from: unix_from,
                    to: unix_to
                }
            }
            paramsFilter.timeData = timeData
            //updateDataNew({ paramsFilter: paramsFilter, settingsMain: SettingsMain })
        }
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
        setParamsFilter(paramsFilter)
        getTableOnDaysData({ paramsFilter: paramsFilter })
        getTableDetailedGradeData({ paramsFilter: ParamsFilter })
    }
    const onChangeRangePicker = (value) => {
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const date_from = moment(value[0].$d).format(dateFormat)
        const date_to = moment(value[1].$d).format(dateFormat)
        let time_from = date_from + ' 00:00:00'
        let time_to = date_to + ' 23:59:59'
        let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
        let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
        paramsFilter.timeData = {
            from: date_from,
            to: date_to,
            value: 'fixed',
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: date_from,
                date_to: date_to
            }
        }
        if (DateSelectOptionsValue === 'fixed') {
            setRangePickerNewValue({
                from: date_from,
                to: date_to
            })

        } else {
            setDateSelectOptionsValue('fixed')
        }

        getTableOnDaysData({ paramsFilter: paramsFilter })
        getTableDetailedGradeData({ paramsFilter: ParamsFilter })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }
    const onChangeProjectNames = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedProjectNames = e
        setParamsFilter(paramsFilter)
        getTableOnDaysData({ paramsFilter: paramsFilter })
        getTableDetailedGradeData({ paramsFilter: paramsFilter })

        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }

    // Таблица "По дням"
    const onGridReadyTableOnDays = useCallback((params) => {
        // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
        //     .then((resp) => resp.json())
        //     .then((data) => setRowData(data));
        getTableOnDaysData({ paramsFilter: ParamsFilter })
    }, []);

    const autoSizeAll = useCallback((e, skipHeader) => {
        const allColumnIds = [];
        TableOnDaysRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        TableOnDaysRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const [TableOnDaysColumnDefs, setTableOnDaysColumnDefs] = useState([
        {
            headerName: 'Дата',
            field: 'call_date',
            sortable: true,
            width: 120
        },


        {
            headerName: 'Звонки',
            suppressStickyLabel: true,
            openByDefault: true,

            children: [
                {
                    headerName: 'Кол-во',
                    field: 'calls_count_total',
                    sortable: true,
                    width: 100

                },
                {
                    headerName: 'Длит.',
                    headerTooltip: 'Длительность звонка',
                    field: 'calls_duration_total',
                    sortable: true,
                    width: 120

                },

            ],
        },
        {
            headerName: 'Статус обработки',
            suppressStickyLabel: true,
            openByDefault: true,
            children: [
                {
                    headerName: 'Длительность',
                    children: [
                        {
                            headerName: 'Запрещено',
                            children: [
                                {
                                    headerName: 'Всего',
                                    field: 'calls_count_duration_denied_total',
                                    columnGroupShow: 'closed',
                                    width: 140
                                },
                                {
                                    headerName: 'Меньше 5 мин',
                                    field: 'calls_count_duration_denied_less_300',
                                    columnGroupShow: 'open',
                                    width: 140
                                },
                                {
                                    headerName: 'Меньше 10 мин',
                                    field: 'calls_count_duration_denied_less_600',
                                    columnGroupShow: 'open',
                                    width: 140
                                },
                                {
                                    headerName: 'Больше 12 мин',
                                    field: 'calls_count_duration_denied_more_720',
                                    columnGroupShow: 'open',
                                    width: 140
                                }
                            ]
                        },
                        {
                            headerName: 'Разрешено',
                            field: 'calls_count_duration_granted_total',
                            width: 140
                        }

                    ]
                },
                {
                    headerName: 'Лимит звонков',
                    children: [
                        {
                            headerName: 'Запрещено',
                            field: 'calls_count_operator_limit_denied_total',
                            width: 140

                        },
                        {
                            headerName: 'Разрешено',
                            field: 'calls_count_operator_limit_granted_total',
                            width: 140
                        }

                    ]
                },


            ],
        },
    ]);

    function getTableOnDaysData(props) {
        //setMainTableSwitchShowExpiredDisabled(true)
        //setMainTableSwitchShowSentimentDisabled(true)
        fetch(URLs.bd_url_read, {
            //mode: 'no-cors',
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getCallsData(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                if (!resp.data) {
                    console.error('!resp.data')
                } else {
                    if (resp.data.length == 0) {
                        console.log('resp.data.length==0')
                        setTableOnDaysRowData([])
                    } else {

                        const data = resp.data
                        //setTableCallsData(resp.data)


                        let data_format_date = []
                        data.map(row => {
                            row.call_date = api.Times.unixTimestampToDate(row.crm_call_date)
                            row.crm_audio_duration = Number(row.crm_audio_duration)
                            //console.log(row.call_date)
                        })

                        const data_1 = AlaSQL(`select call_date, 
                        count(*) calls_count_total,
                        sum(crm_audio_duration) calls_duration_total,
                        sum( 
                            case when CHARINDEX('audio len', transcribe_reason) > 0  then 1
                            else 0
                            end
                            ) calls_count_duration_denied_total,

                        sum( 
                            case when transcribe_reason = 'audio len more 720' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_more_720,

                        sum( 
                            case when transcribe_reason = 'audio len less 600' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_less_600,
                            
                        sum( 
                            case when transcribe_reason = 'audio len less 300' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_less_300,

                        sum( 
                                case when transcribe_permission = 'granted'  then 1
                                else 0
                                end
                                ) calls_count_duration_granted_total,

                        sum( 
                            case when transcribe_permission = 'granted'  and transcribe_reason in ('limit ok', 'ok') then 1
                            else 0
                            end
                            ) calls_count_operator_limit_granted_total,

                        sum( 
                            case when transcribe_permission = 'denied'  and transcribe_reason in ('operators limit') then 1
                            else 0
                            end
                            ) calls_count_operator_limit_denied_total

                        from ? 
                        group by call_date
                        `, [data])
                        //console.log(data_1)

                        // const calls_len = data.length
                        // setStatisticsCallsLen(calls_len)
                        // const totalSum = data.reduce((sum, item) => sum + Number(item.crm_audio_duration), 0);
                        // console.log(totalSum); // Выведет: 60
                        // const _statistics_calls_total_time = api.Times.secondsToTimeFormat(totalSum, 1)
                        // setStatisticsCallsTotalTime(_statistics_calls_total_time)
                        // console.log(totals)

                        setTableOnDaysRowData(data_1)
                    }
                }
            })
    }

    const onRowClickTableOnDays = (row_data) => {
        console.log(row_data)


    }

    // Таблица по сотрудникам
    const [TableOnOperatorsColumnDefs, setTableOnOperatorsColumnDefs] = useState([

        {
            headerName: 'Сотрудник',
            field: 'crm_operator_name',
            sortable: true
        },
        {
            headerName: 'Всего анализов',
            field: 'count_alanysis_total',
            sortable: true
        },
        {
            headerName: 'Общее кол-во времени',
            field: 'crm_audio_duration_total',
            sortable: true,
            cellRenderer: (data) => {
                return api.Times.genOutTime({ seconds: data.value })
            }
        },
        {
            headerName: 'Общий средний балл',
            field: 'avg_grades_total',
            sortable: true,
            cellRenderer: (data) => {
                return (data.value).toFixed(1)
            }
        },


    ]);

    const TableOnOperatorsAutoSizeToFit = useCallback(() => {
        if (TableOnOperatorsGridRef) {
            if (TableOnOperatorsGridRef.current) {
                if (TableOnOperatorsGridRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        TableOnOperatorsGridRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);
    const TableOnOperatorsMultipleRowsSelected = (gridApi) => {
        const selectedData = gridApi.getSelectedRows();
        // Делаем что-то с selectedData
        console.log(selectedData)
    };


    // Таблица оценок
    const [TableDetailedGradeСolumnDefs, setTableDetailedGradeСolumnDefs] = useState([
        {
            headerName: 'Дата',
            field: 'analysis_unix',
            sortable: true,
            //comparator: api.Times.dateComparator
            cellRenderer: (data) => {
                const unix = data.value
                return api.Times.unixTimestampToDateTime(unix * 1000)
            }
        },
        {
            headerName: 'Бот',
            field: 'bot',
            sortable: true
        },
        {
            headerName: 'Сделка',
            field: 'deal_id',
            cellRenderer: (data) => {
                const data0 = data.value
                const s = data0.split('__')
                let lead_id = s[1]
                let project_name = s[0] ? s[0] : ""
                let deal_url = ""
                if (project_name === 'tutgood') {
                    deal_url = `https://tutgood.amocrm.ru/leads/detail/${lead_id}`
                }
                return <a href={deal_url} target="_blank">{lead_id}</a>

            }
        },
        {
            headerName: 'Сотрудник',
            field: 'crm_operator_name',
            sortable: true
        },
        {
            headerName: 'Длительность',
            field: 'crm_audio_duration',
            sortable: true,
            cellRenderer: (data) => {
                return api.Times.genOutTime({ seconds: data.value })
            }
        },
        {
            headerName: 'Баллы по этапам',
            suppressStickyLabel: true,
            openByDefault: false,
            sortable: true,
            children: [
                {
                    headerName: 'Всего',
                    field: 'grades.total',
                    sortable: true,
                    columnGroupShow: 'closed',

                },
                {
                    headerName: 'Подготовка к продаже',
                    field: 'grades.preparing_to_sell',
                    sortable: true,
                    columnGroupShow: 'open',

                },

                {
                    headerName: 'Установление контакта',
                    field: 'grades.establishing_and_maintaining_contact',
                    sortable: true,
                    columnGroupShow: 'open',

                },

                {
                    headerName: 'Квалификация',
                    field: 'grades.qualification',
                    sortable: true,
                    columnGroupShow: 'open',

                },
                {
                    headerName: 'Презентация',
                    field: 'grades.presentation',
                    sortable: true,
                    columnGroupShow: 'open',

                }
            ]
        },
    ]);
    const TableDetailedGradeOnGridReady = useCallback((params) => {
        getTableDetailedGradeData({ paramsFilter: ParamsFilter })
    }, []);

    const TableDetailedGradeSizeToFit = useCallback(() => {
        if (TableDetailedGradeRef) {
            if (TableDetailedGradeRef.current) {
                if (TableDetailedGradeRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        TableDetailedGradeRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);
    function getTableDetailedGradeData(props) {
        //setMainTableSwitchShowExpiredDisabled(true)
        //setMainTableSwitchShowSentimentDisabled(true)
        fetch(URLs.bd_url_read, {
            //mode: 'no-cors',
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getDetailedGradeData2(props)
        })
            .then((resp_json) => {
                if (!resp_json.ok) {
                    throw new Error(`Network response was not ok: ${resp_json.status}`);
                }
                return resp_json.json();
            })
            .then((resp) => {
                //console.log(resp)
                if (!resp.data) {
                    console.error('!resp.data');
                } else {
                    if (resp.data.length === 0) {
                        console.log('resp.data.length === 0');
                        setTableDetailedGradeRowData([]);
                    } else {
                        const data = resp.data;
                        console.log(data);

                        data.map(row => {
                            const crm_audio_duration = row.crm_audio_duration;
                            row.crm_audio_duration = Number(row.crm_audio_duration);
                            row.crm_audio_duration_str = `${(crm_audio_duration / 60).toFixed(0)} мин`;

                            let preparing_to_sell = 0//'N/A'
                            let establishing_and_maintaining_contact = 0//'N/A'
                            let dialogue_purpose_plan = 0//'N/A'
                            let presentation = 0//'N/A'
                            let price = 0//'N/A'
                            let closing_deal = 0//'N/A'
                            let qualification = 0//'N/A'
                            let instruction_free_lesson = 0



                            let deal_id = row.project_name + '__' + row.crm_lead_id
                            row.deal_id = deal_id

                            let tariff_presentation = 0//'N/A'
                            let objection_handling = 0//'N/A'

                            const json_analysis = row.json_analyze
                            let analysis = {}
                            if (json_analysis) {
                                if (JSON.parse(json_analysis)) {
                                    const json_analysis_parse = JSON.parse(json_analysis)
                                    //console.log(json_analysis_parse)

                                    analysis = json_analysis_parse.analysis
                                    if (analysis) {
                                        if (analysis.length > 0) {
                                            analysis.map(row => {
                                                const { stage } = row
                                                const { stage_grade } = row

                                                if (stage === 'Preparing to sell') {
                                                    preparing_to_sell = stage_grade
                                                }

                                                if ([
                                                    'Establishing and maintaining contact',
                                                    'Установление контакта'
                                                ].includes(stage)) {
                                                    establishing_and_maintaining_contact = stage_grade
                                                }

                                                if ([
                                                    'Квалификация'
                                                ].includes(stage)) {
                                                    qualification = stage_grade
                                                }

                                                if (stage === 'Dialogue purpose/plan') {
                                                    dialogue_purpose_plan = stage_grade
                                                }


                                                if ([
                                                    'Презентация продукта с выгодой для клиента',
                                                    'Презентация',
                                                    'Presentation'
                                                ].includes(stage)) {
                                                    presentation = stage_grade
                                                }

                                                if ([
                                                    "Инструкция бесплатное занятие"
                                                ].includes(stage)) {
                                                    instruction_free_lesson = stage_grade
                                                }


                                                if (stage === 'Price') {
                                                    price = stage_grade
                                                }

                                                if ([
                                                    'Дожим до продажи',
                                                    'Closing Deal'
                                                ].includes(stage)) {
                                                    closing_deal = stage_grade
                                                }


                                                if ([
                                                    'Презентация  тарифов',
                                                ].includes(stage)) {
                                                    tariff_presentation = stage_grade
                                                }

                                                if ([
                                                    'Отработка возражений',
                                                ].includes(stage)) {
                                                    objection_handling = stage_grade
                                                }



                                            })

                                        }
                                    }

                                }
                            }

                            //_table_1_data.push({
                            //key: rowData['Оператор'] + "_" + rowData['Дата создания'],
                            //date: rowData['Дата создания'],
                            //analysis: analysis,
                            //crm_operator_name: rowData['Оператор'].replace(' (amoCRM)',''),
                            //bot_name: rowData['Бот анализатор'],
                            row.grades = {
                                preparing_to_sell: preparing_to_sell,
                                qualification: qualification,
                                establishing_and_maintaining_contact: establishing_and_maintaining_contact,
                                dialogue_purpose_plan: dialogue_purpose_plan,
                                presentation: presentation,
                                price: price,
                                closing_deal: closing_deal,
                                tariff_presentation: tariff_presentation,
                                objection_handling: objection_handling,
                                instruction_free_lesson: instruction_free_lesson,

                                total: preparing_to_sell + qualification + establishing_and_maintaining_contact
                                    + dialogue_purpose_plan + presentation + price
                                    + closing_deal + tariff_presentation
                                    + objection_handling +
                                    instruction_free_lesson



                            }
                            row.grades_total = row.grades.total

                        });

                        setTableDetailedGradeRowData(data);
                        TableDetailedGradeSizeToFit()

                        const _on_operators_data = AlaSQL(`select 
                        crm_operator_name, count(*) count_alanysis_total,

                        sum(crm_audio_duration) crm_audio_duration_total,
                        avg(grades_total) avg_grades_total
                        
                        
                        from ?
                        group by crm_operator_name
                        `, [data])
                        setTableOnOperatorsRowData(_on_operators_data)
                        TableOnOperatorsAutoSizeToFit()
                    }
                }
            })
            .catch((error) => {
                console.error(`Fetch error: ${error}`);
            });
    }


    return (<>

        <Row>
            <Card size='small' style={{ width: '100%' }}>
                <Row>
                    <Col span={6}>
                        Проект
                    </Col>
                    <Col span={6}>
                        <Select
                            mode="multiple"
                            tagRender={tagRender}
                            defaultValue={[]}
                            style={{
                                width: '100%',
                            }}
                            value={ParamsFilter.selectedProjectNames ? ParamsFilter.selectedProjectNames : []}
                            options={ProjectNames}
                            onChange={onChangeProjectNames}
                        />
                    </Col>
                    <Col span={6}>
                        <CustomRangePicker
                            defaultFrom={ParamsFilter.timeData.work.date_from}
                            defaultTo={ParamsFilter.timeData.work.date_to}
                            valueFrom={RangePickerNewValue ? (
                                RangePickerNewValue.from ? RangePickerNewValue.from :
                                    ParamsFilter.timeData.work.date_from
                            ) : ParamsFilter.timeData.work.date_from}
                            valueTo={RangePickerNewValue ? (
                                RangePickerNewValue.to ? RangePickerNewValue.to :
                                    ParamsFilter.timeData.work.date_to
                            ) : ParamsFilter.timeData.work.date_to}
                            onChange={onChangeRangePicker}
                        ></CustomRangePicker>
                    </Col>

                    <Col span={6}>
                        <AntDateSelectOptions
                            defaultValue={ParamsFilter.timeData.value}
                            options={DateSelectOptionsRaw}
                            onChange={onChangeAntDateSelectOptions}
                            value={DateSelectOptionsValue}
                        ></AntDateSelectOptions>
                    </Col>
                </Row>

            </Card>


        </Row>



        <Row>

            <Col span={12}>
                <Card title='По дням' style={containerStyle}>
                    <div style={containerStyle}>
                        <div style={{ height: 540 }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={TableOnDaysRef}
                                rowData={TableOnDaysRowData}
                                columnDefs={TableOnDaysColumnDefs}
                                defaultColDef={defaultColDef}
                                //onGridReady={onGridReadyTableOnDays}
                                onGridReady={(e) => {
                                    onGridReadyTableOnDays(e)
                                    //autoSizeAll(e, false)
                                }}
                                onRowClicked={(e) => {
                                    const data = e.data
                                    onRowClickTableOnDays(data)
                                }}
                                pagination={true}
                                paginationPageSize={10}
                            />
                        </div>
                    </div>
                </Card>

            </Col>
            <Col span={12}>
                <Card title='По сотрудникам' style={containerStyle} size="small">
                    Готовые анализы по сотрудникам
                    <div style={containerStyle}>
                        <div style={{ height: 540 }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={TableOnOperatorsGridRef}
                                rowData={TableOnOperatorsRowData}
                                columnDefs={TableOnOperatorsColumnDefs}
                                defaultColDef={defaultColDef}

                                pagination={true}
                                paginationPageSize={10}
                                multiSortKey={'ctrl'}
                                animateRows={true}
                                onRowClicked={(e) => {
                                    //const data = e.data
                                    //onRowClickTableOnDays(data)



                                    const selectedData = e.api.getSelectedRows();
                                    const selectedOperators = AlaSQL('select distinct crm_operator_name crm_operator_name from ?', [selectedData])
                                    setTableOnOperatorsSelectedOperators(selectedOperators[0])

                                    if (selectedOperators) {
                                        if (selectedOperators.length > 0) {
                                            const transformedArray = selectedOperators.map(obj => obj.crm_operator_name);

                                            console.log(transformedArray)
                                        }
                                    }






                                }}
                                rowSelection={'multiple'}

                            />
                        </div>
                    </div>
                </Card>

            </Col>

            <Card title='Подробные анализы' style={containerStyle} size="small">
                Анализы с оценками
                <div style={containerStyle}>
                    <div style={{ height: 540 }} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef}
                            rowData={TableDetailedGradeRowData}
                            columnDefs={TableDetailedGradeСolumnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={TableDetailedGradeOnGridReady}

                            pagination={true}
                            paginationPageSize={10}
                        />
                    </div>
                </div>
            </Card>

        </Row>

    </>)
}

export default DashboardStatic