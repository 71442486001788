import api from '../../index'
import alasql from 'alasql';
/*export async function getTranscripsByUid(uid) {
    return await api.urlFetch.fetchData({
        url: api.Aiwin.CONFIG.api_url + 'method/' + api.Aiwin.CONFIG.API_METHODS.GET_TRANSCRIPTS_BY_UID,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': api.Aiwin.CONFIG.api_auth
        },
        body: JSON.stringify({ uid: uid })
    })
}*/

export async function getTranscripsByUid(uid) {
    try {
        const response = await fetch('https://us-central1-spy2money.cloudfunctions.net/aiwin/1/method/getTranscriptsByUid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer Akm2eucYXflDuHFaUfbSaY9Nw93'
            },
            body: JSON.stringify({ uid: uid })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error; // Проброс ошибки
    }
}

export async function getDialogByMeetingId(meetingId) {
    try {
        const response = await fetch('https://us-central1-spy2money.cloudfunctions.net/aiwin/1/method/getDialogByMeetingId', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer Akm2eucYXflDuHFaUfbSaY9Nw93'
            },
            body: JSON.stringify({ meetingId: meetingId })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error; // Проброс ошибки
    }
}

export class TranscriptData {
    constructor(uid) {
        this.uid = uid;
        this.raw = {};
        this.blocks = [];
        this.init();
        this.speakers = []
    }

    async init() {
        try {
            const data = await getTranscripsByUid(this.uid);
            if (data && data.status === 'ok') {
                this.raw = data;
            }
        } catch (error) {
            // Обработка ошибки
            console.error(error);
        }
    }

    // speakers

    initSpeakers() {
        const speakersObj = alasql(`select distinct speakerName from ?`, [this.raw.data])
        const speakersArr = speakersObj.map(row => {
            return row.speakerName
        })
        this.speakers = speakersArr
    }

    getSpeakers() {
        return this.speakers
    }

    getRaw() {
        return this.raw;
    }

    // list
    getList() {
        return alasql(`
        select createdAt key, createdAt, meetingId from
        (select distinct createdAt, meetingId from ?)
        `, [this.raw.data])
    }


}

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
    } else {
        return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
    }
}


function padZero(number) {
    return number.toString().padStart(2, '0');
}

function combineTexts(transcripts) {
    const combinedTranscripts = [];
    let previousAuthor = '';

    for (let i = 0; i < transcripts.length; i++) {
        const currentTranscript = transcripts[i];
        const currentAuthor = currentTranscript.speakerName;
        const currentText = currentTranscript.transcript;

        if (currentAuthor === previousAuthor) {
            const lastCombinedTranscript = combinedTranscripts[combinedTranscripts.length - 1];
            lastCombinedTranscript.transcript += `. ${currentText}`;
        } else {
            combinedTranscripts.push({
                time: currentTranscript.time,
                speakerName: currentAuthor,
                transcript: currentText
            });
        }

        previousAuthor = currentAuthor;
    }

    return combinedTranscripts;
}

export class Dialog {
    constructor(id) {
        this.id = id;
        this.raw = {};
        this.blocks = [];
        this.init();
        this.times = {}
        this.list = []
    }

    async init() {
        try {
            const data = await getDialogByMeetingId(this.id);
            if (data && data.status === 'ok') {
                // const optData = alasql(`select *  from ? order by timestamp`, [data.data])
                this.raw = data;
            }
        } catch (error) {
            // Обработка ошибки
            console.error(error);
        }
    }

    //times 

    async initTimes() {
        //console.log('initTimes')
        //console.log(this.raw.data)
        const sqlObj = alasql(` select min(numberTimestamp) as timestamp_start from
        (SELECT id, CAST(timestamp AS NUMBER) AS numberTimestamp FROM ? ) 
        `, [this.raw.data]);

        //console.log(sqlObj)
        this.times = {
            timestamp_start: Number(sqlObj[0].timestamp_start)
        }
    }


    getSpeakers() {
        return this.speakers
    }

    getRaw() {
        return this.raw;
    }

    // list
    async prepareList() {
        let outList = []
        const preList = this.list

        preList.map(row => {
            //let time = Number(row.timestamp) - this.times.timestamp_start
            //let t_seconds = (time / 1000).toFixed(0)
            let timeStr = '00:00'
            if (row.time > 0) {
                timeStr = formatTime(row.time);
            }
            outList.push({
                timeStr: timeStr,
                speakerName: row.speakerName,
                transcript: row.transcript
            })
        })

        this.list = outList

    }

    async combineList() {
        let outList = []
        const preList = alasql(`
        select * from
        (select * from ? order by timestamp)
        `, [this.raw.data])
        preList.map(row => {
            let time = Number(row.timestamp) - this.times.timestamp_start
            let t_seconds = (time / 1000).toFixed(0)
            outList.push({
                time: t_seconds,
                //time: time,
                //timeStr: timeStr,
                speakerName: row.speakerName,
                transcript: row.transcript
            })
        })
        let combineTextList = combineTexts(outList)
        this.list = combineTextList
    }

    getList() {
        return this.list
    }



}


export class Dialog2 {
    constructor(id) {
      this.id = id;
      this.raw = {};
      this.list = [];
      this.init();
    }
  
    async init() {
      try {
        const data = await getDialogByMeetingId(this.id);
        if (data && data.status === 'ok') {
          this.raw = data;
          await this.combineList();
          await this.prepareList();
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    async combineList() {
      const dataList = this.raw.data.sort((a, b) => a.timestamp - b.timestamp);
      let outList = [];
      let previousAuthor = '';
      let combinedText = '';
  
      for (let i = 0; i < dataList.length; i++) {
        const { timestamp, speakerName, transcript } = dataList[i];
        const currentAuthor = speakerName.split(':')[0];
        const currentText = transcript.split(':')[1];
  
        if (currentAuthor === previousAuthor) {
          combinedText += ` . ${currentText}`;
        } else {
          if (combinedText) {
            outList.push({ speakerName: previousAuthor, transcript: combinedText });
          }
          previousAuthor = currentAuthor;
          combinedText = currentText;
        }
      }
  
      if (combinedText) {
        outList.push({ speakerName: previousAuthor, transcript: combinedText });
      }
  
      this.list = outList;
    }
  
    async prepareList() {
      const formattedList = this.list.map(({ speakerName, transcript }) => ({
        speakerName,
        transcript,
        timeStr: formatTime(transcript.time)
      }));
  
      this.list = formattedList;
    }
  
    getList() {
      return this.list;
    }
  }
  
