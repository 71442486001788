import React, { useMemo } from "react";
import { Button, Tag } from "antd";
import api from '../../api'
import BtnCellRenderer from "./BtnCellRenderer ";

export const defaultColDef = {
    resizable: true,
    initialWidth: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
};

export const TableOnDays_ColumnDefs = [
    {
        headerName: 'Дата',
        field: 'call_date',
        sortable: true,

    },
    {
        headerName: 'Звонки',
        field: 'calls_count_total',
        sortable: true,
    },

    {
        headerName: 'Отклонено',
        field: 'calls_count_duration_denied_total',
        sortable: true,
    },
    {
        headerName: 'Принято',
        field: 'calls_count_grantd_total',
        sortable: true,
    },
    {
        headerName: 'Длительность',
        field: 'calls_duration_granted_total',
        sortable: true,
        cellRenderer: (data) => {
            return api.Times.genOutTime({ seconds: data.value })
        }
    }


]


// Таблица по сотрудникам
export const TableOnOperators_ColumnDefs = [

    {
        headerName: 'Сотрудник',
        field: 'crm_operator_name',
        sortable: true
    },
    {
        headerName: 'Всего анализов',
        field: 'count_alanysis_total',
        sortable: true
    },
    {
        headerName: 'Общее кол-во времени',
        field: 'crm_audio_duration_total',
        sortable: true,
        cellRenderer: (data) => {
            return api.Times.genOutTime({ seconds: data.value })
        }
    },
    {
        headerName: 'Общий средний балл',
        field: 'avg_grades_total',
        sortable: true,
        cellRenderer: (data) => {
            return (data.value).toFixed(1)
        }
    },


];


export const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const color = {
        'geometry': 'gold',
        'tutgood': 'blue',
        'likecenter': 'lime'
    }
    return (
        <Tag
            color={color[value]}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};




