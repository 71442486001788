import axios from "axios";

const cors = require('cors')({ origin: true });
//const express = require('express')


const config = {
    ch_url: 'https://db.vixprofit.com/?add_http_cors_header=1&user=default&password=&default_format=JSON',
    ch_headers: {
        accept: '*/*',
        'content-type': 'text/plain;charset=UTF-8',
        //'sec-fetch-dest': 'empty',
        //'sec-fetch-mode': 'cors', //and type='SWEEP'
    }
}

export async function urlAxios(props) {
    console.log('urlAxios')
    const url = props.url
    const body = props.body

    const config = {
        headers: props.headers ? props.headers : {
            accept: '*/*',
            'content-type': 'text/plain;charset=UTF-8'
        }
    };

    let r = {}
    if (props.method) {
        if (props.method === 'post' || props.method === 'POST') {
            return axios.post(url, body, config)
                .then(response => {
                    r.status = 'ok'
                    r.data = response.data
                    return r
                })
                .catch(error => {
                    r.status = 'error'
                    r.message = error
                    return r
                });
        }
    }

    if (props.method) {
        if (props.method === 'get' || props.method === 'GET') {

            return axios.get(url
            )
                .then(response => {
                    r.status = 'ok'
                    r.data = response.data
                    return r
                })
                .catch(error => {
                    r.status = 'error'
                    r.message = error
                    return r
                });

        }
    }

}

export async function urlAxiosCH(props) {
    console.log('urlAxiosCH')
    const body = props.body
    const params = { headers: config.ch_headers }
    let r = {}
    if (props.method) {
        if (props.method === 'post' || props.method === 'POST') {
            return axios.post(config.ch_url, body, params)
                .then(response => {
                    r.status = 'ok'
                    r.data = response.data
                    return r
                })
                .catch(error => {
                    r.status = 'error'
                    r.message = error
                    return r
                });
        }
    }

}


async function urlPostFetch({ url, headers, body }) {
    return fetch(url, {
        method: 'POST', // или 'PUT'
        headers: headers,
        // Тело запроса
        body: body
    })
        .then(response => response.json())
        .then(data => {
            return ({ status: 'ok', data: data })
        })
        .catch(error => {
            return ({ status: 'error', message: error })
        });
}

export async function fetchData({ url, headers, body }) {
    try {
        const response = await urlPostFetch({
            url: url,
            headers: headers,
            body: body
        });
        return response; // Ответ сервера
    } catch (error) {
        return error; // Ошибка запроса
    }
}


export const URLs = {
    flowline_api: 'https://europe-west3-aivix-324522.cloudfunctions.net/reload_flowclan_symbol',
    //bd_url: 'https://db.vixprofit.com/?add_http_cors_header=1&user=deep&password=qwerty&default_format=JSON',
    bd_url:'https://db.vixprofit.com/?add_http_cors_header=1&user=default&password=&default_format=JSON',
    //flowline_api: 'https://us-central1-aivix-324522.cloudfunctions.net/reload_flowclan_symbol'
    bd_headers: {
        //accept: '*/*',
        'Content-Type': 'application/json; charset=UTF-8',
        //'sec-fetch-dest': 'empty',
        //'sec-fetch-mode': 'cors', //and type='SWEEP'
    },
    bd_url_read:'https://db.aiwin.app/?user=default&password=Qaz1Wsx2Edc3&default_format=JSON',
    //https://db.aiwin.app/play?user=default#c2VsZWN0ICogZnJvbSBBbmFseXNpczI=

}

