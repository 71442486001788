import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
//import LoginPage from './pages/auth/LoginPage2';
//import LoginPage from './pages/auth/LoginPage3';
import './App.css';
import { Button } from 'antd';
import { getAuth } from '@firebase/auth';
import Main from './Main';
import { getUserDocById, synchWithExtension } from "./services/firebase";
import DialogContent from './pages/Transcripts/DialogContent';
import api from './api'
const auth = getAuth();
const menu_default = 'dashboard'

const pagePosition = 'aiwin-main'
function AppFirebase() {
    const [loginPreloader, setLoginPreloader] = useState(true)
    const [user, setUser] = useState(null);
    const menuSelectedItem = api.localStore.getFromLocalStorage(pagePosition + '-menu-selected')
        ? api.localStore.getFromLocalStorage(pagePosition + '-menu-selected') : menu_default

    const [usedPathName, setUsedPathName] = useState('/app/' + menuSelectedItem)
    const [userData, setUserData] = useState(null);
    let navigate = useNavigate();


    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                const uid = user.uid

                const getUserData = async () => {
                    const userDoc = await getUserDocById(uid)
                    if (userDoc.status === 'ok') {
                        if (userDoc.data) {
                            const data = userDoc.data
                            setUserData(data)
                        } else {

                        }
                    }
                    navigate('/app')
                };
                getUserData()
                //synchWithExtension({ uid: uid, email: user.email, displayName: user.displayName })
            }
            if (!user) {
                setLoginPreloader(false)
                navigate('/login')
            }
            setUser(user)
        })
    }, [user])


    const ProtectedRoute = ({ user, children }) => {
        if (!user) { return <Navigate to="/login" replace />; } return children;
    };


    return (<>
        <Routes>
            <Route exact path="/app/*" element={<Navigate to={usedPathName} />} />

            <Route path="/app/*" element={
                <ProtectedRoute user={user}> <Main user={user} userData={userData} />
                </ProtectedRoute>} >

                <Route path=":type" >
                </Route>
            </Route>

            <Route path="/login" element={<LoginPage preloader={loginPreloader} />} />



        </Routes>

        <Outlet />
    </>
    )
}

export default AppFirebase;