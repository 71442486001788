import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import * as MAIN_CONFIG from '../../../MainConfig'
import api from "../../../api";
import { Table } from 'antd';

const main_strs = MAIN_CONFIG.getMainStrings()
const columns = [
    {
        title: 'Время',
        dataIndex: 'timeStr',
        
    },
    {
        title: 'Автор',
        dataIndex: 'speakerName',
    },
    {
        title: 'Текс',
        dataIndex: 'transcript',
    }
];


const DialogContent = () => {
    const { meetingId } = useParams();
    const [ dialogList, setDialogList ] = useState(null)



    useEffect(() => {

        async function fetchDataAndProcess(meetingId) {
            const classData = new api.Aiwin.Transcripts.Dialog(meetingId)
            await classData.init();
            const rawData = classData.getRaw();
            //console.log(rawData);
            await classData.initTimes()
            //const speakers = transcriptData.getSpeakers()
            //console.log(speakers);
            await classData.combineList()
            await classData.prepareList()

            const list = classData.getList()
            setDialogList(list)
            //console.log(list)
        }

        fetchDataAndProcess(meetingId);

    }, []);

    return (
        <>
            <PageContainer
                title={main_strs.transcript_page_title}
                children={<>

                    {meetingId}

                    <Table
                    size="small"
                        columns={columns}
                        dataSource={dialogList}
                        
                       
                    />
                </>}
            ></PageContainer>
        </>
    );
};

export default DialogContent;
