import React, { Component } from "react";
import { Button } from "antd";

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    
    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }
    
    render() {
        // Если значение пустое или не определено, не отображать кнопку
        if (!this.props.value) {
            return null;
        }

        return <Button onClick={this.btnClickedHandler}>Просмотр</Button>;
    }
}

export default BtnCellRenderer;
