import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Button, Breadcrumb,
    Card, Col,
    Modal,
    Row,
    Select, Spin
} from "antd";
import { AgGridReact } from "ag-grid-react";
import AlaSQL from "alasql";
import moment from "moment-timezone";
import AntDateSelectOptions from '../../components/AntDateSelectOptions';
import CustomRangePicker from '../../components/CustomRangePicker';
import * as COLUMNS from './columns'
import * as CONFIG from './config'
import * as QUERYS from './querys'
import api from '../../api'
import BtnCellRenderer from "./BtnCellRenderer ";
import axios from 'axios';
import { Typography } from 'antd';

const { Title, Text } = Typography;
const fetchContent = async (url) => {
    const response = await axios.get(url);
    return response.data;
};

AlaSQL.fn.CHARINDEX = function (substring, string) {
    return string.indexOf(substring) + 1; // JavaScript 'indexOf' возвращает -1 если не найдено, иначе позицию начиная с 0. SQL начинает с 1.
};

const defaultDateFormat = 'DD-MM-YYYY';
const pagePosition = 'aiwin_dashboard'

const DashboardStatic_v2 = ({ userData }) => {
    //console.log(userData)

    const containerStyle = useMemo(() => ({
        width: '100%', height: '100%',
        margin: 5
    }), []);
    // Основное
    const smW = 500

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            TableOnDays_SizeToFit()
            TableDetailedGrades_SizeToFit()
        };

        window.addEventListener('resize', handleResize);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [ParamsFilter, setParamsFilter] = useState(
        JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
            : CONFIG.defaultParamsFilter
    )
    const [ProjectNames, setProjectNames] = useState(userData.projects)
    // Фильтры
    const [RangePickerNewValue, setRangePickerNewValue] = useState(null)
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(api.Times.initDateRange())
    // Таблица по дням
    const TableOnDays_GridRef = useRef()
    const [TableOnDays_RowData, setTableOnDays_RowData] = useState()
    // Таблица по сотрудникам
    const TableOnOperators_GridRef = useRef()
    const [TableOnOperators_RowData, setTableOnOperators_RowData] = useState()
    const [TableOnOperators_FilteredRowData, setTableOnOperators_FilteredRowData] = useState()
    // Таблица по оценкам
    const TableDetailedGrades_GridRef = useRef()
    const [TableDetailedGrades_RowData, setTableDetailedGrades_RowData] = useState()
    const [TableDetailedGrades_FilteredRowData, setTableDetailedGrades_FilteredRowData] = useState()
    // Modal report 
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(null);
    const [bid, setBid] = useState(null);

    const showModal = (field) => {
        setBid(field)
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };



    // Фильтры основные
    const onChangeAntDateSelectOptions = (e) => {
        const pickerRangeValue = DateSelectOptionsRaw[e]
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const time_from = pickerRangeValue.work.date_from + ' 00:00:00'
        const time_to = pickerRangeValue.work.date_to + ' 23:59:59'
        setDateSelectOptionsValue(e)
        if (e === 'today') {
            //setSignalWorkCheck(true)
            paramsFilter.signalWorkCheck = true
        } else {
            //setSignalWorkCheck(false)
            paramsFilter.signalWorkCheck = false
        }
        if (e === 'fixed') {
            paramsFilter.timeData = pickerRangeValue
        } else {
            const mFromTo = [
                moment(moment(pickerRangeValue.work.date_from), defaultDateFormat),
                moment(moment(pickerRangeValue.work.date_to), defaultDateFormat)
            ]
            const newDateFrom = moment(pickerRangeValue.work.date_from).format(dateFormat)
            const newDateTo = moment(pickerRangeValue.work.date_to).format(dateFormat)
            setRangePickerNewValue({
                from: newDateFrom,
                to: newDateTo
            })
            let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
            let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
            let timeData = {
                from: newDateFrom,
                to: newDateTo,
                //from_to: mFromTo,
                value: e,
                work: pickerRangeValue.work,
                visual: pickerRangeValue.visual,
                unix: {
                    from: unix_from,
                    to: unix_to
                }
            }
            paramsFilter.timeData = timeData
            //updateDataNew({ paramsFilter: paramsFilter, settingsMain: SettingsMain })
        }
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
        setParamsFilter(paramsFilter)
        TableOnDays_GetData({ paramsFilter: paramsFilter, userData: userData })
        TableOnOperators_GetData({ paramsFilter: paramsFilter, userData: userData })
    }
    const onChangeRangePicker = (value) => {
        const dateFormat = 'YYYY-MM-DD'
        let paramsFilter = ParamsFilter;
        const date_from = moment(value[0].$d).format(dateFormat)
        const date_to = moment(value[1].$d).format(dateFormat)
        let time_from = date_from + ' 00:00:00'
        let time_to = date_to + ' 23:59:59'
        let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
        let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
        paramsFilter.timeData = {
            from: date_from,
            to: date_to,
            value: 'fixed',
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: date_from,
                date_to: date_to
            }
        }
        if (DateSelectOptionsValue === 'fixed') {
            setRangePickerNewValue({
                from: date_from,
                to: date_to
            })

        } else {
            setDateSelectOptionsValue('fixed')
        }

        TableOnDays_GetData({ paramsFilter: paramsFilter, userData: userData })
        TableOnOperators_GetData({ paramsFilter: paramsFilter, userData: userData })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }
    const onChangeProjectNames = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedProjectNames = e
        setParamsFilter(paramsFilter)

        TableOnDays_GetData({ paramsFilter: paramsFilter, userData: userData })
        TableOnOperators_GetData({ paramsFilter: paramsFilter, userData: userData })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }



    // Таблица по дням
    const TableOnDays_GridReady = useCallback((params) => {
        TableOnDays_GetData({ paramsFilter: ParamsFilter, userData: userData })
    }, []);
    function TableOnDays_GetData(props) {
        //setMainTableSwitchShowExpiredDisabled(true)
        //setMainTableSwitchShowSentimentDisabled(true)
        fetch(api.urlFetch.URLs.bd_url_read, {
            //mode: 'no-cors',
            method: 'POST',
            headers: api.urlFetch.URLs.bd_headers,
            body: QUERYS.TableOnDays_GetQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                if (!resp.data) {
                    //console.error('!resp.data')
                } else {
                    if (resp.data.length == 0) {
                        //console.log('resp.data.length==0')
                        setTableOnDays_RowData([])
                    } else {

                        const data = resp.data
                        //setTableCallsData(resp.data)


                        let data_format_date = []
                        data.map(row => {
                            row.call_date = api.Times.unixTimestampToDate(row.crm_call_date)
                            row.crm_audio_duration = Number(row.crm_audio_duration)
                            //console.log(row.call_date)
                        })

                        const data_1 = AlaSQL(`select 
                        * ,
                        calls_count_total - calls_count_duration_denied_total calls_count_grantd_total
                        
                        from(select call_date, 
                        count(*) calls_count_total,
                        sum(crm_audio_duration) calls_duration_total,
                        sum( 
                            case when CHARINDEX('audio len', transcribe_reason) > 0  then 1
                            else 0
                            end
                            ) calls_count_duration_denied_total,

                        sum( 
                            case when transcribe_reason = 'audio len more 720' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_more_720,

                        sum( 
                            case when transcribe_reason = 'audio len less 600' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_less_600,
                            
                        sum( 
                            case when transcribe_reason = 'audio len less 300' and transcribe_permission = 'denied' then 1
                            else 0
                            end
                            ) calls_count_duration_denied_less_300,

                        sum( 
                                case when transcribe_permission = 'granted'  then 1
                                else 0
                                end
                                ) calls_count_duration_granted_total,

                        sum( 
                            case when transcribe_permission = 'granted'  and transcribe_reason in ('limit ok', 'ok') then 1
                            else 0
                            end
                            ) calls_count_operator_limit_granted_total,


                        sum( 
                            case when transcribe_permission = 'denied'  then 1
                            else 0
                            end
                            ) calls_count_operator_limit_denied_total,

                            sum( 
                                case when transcribe_permission = 'denied'  then crm_audio_duration
                                else 0
                                end
                                ) calls_duration_granted_total

                        from ? 
                        group by call_date ) a
                        `, [data])

                        //console.log(data_1)

                        // const calls_len = data.length
                        // setStatisticsCallsLen(calls_len)
                        // const totalSum = data.reduce((sum, item) => sum + Number(item.crm_audio_duration), 0);
                        // console.log(totalSum); // Выведет: 60
                        // const _statistics_calls_total_time = api.Times.secondsToTimeFormat(totalSum, 1)
                        // setStatisticsCallsTotalTime(_statistics_calls_total_time)
                        // console.log(totals)

                        setTableOnDays_RowData(data_1)
                        TableOnDays_SizeToFit()
                    }
                }
            })
    }
    const TableOnDays_SizeToFit = useCallback(() => {
        if (TableOnDays_GridRef) {
            if (TableOnDays_GridRef.current) {
                if (TableOnDays_GridRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        TableOnDays_GridRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);
    function TableOnDays_SelectRows(rows) {
        //setTableDetailedGrades_SelectedOperators(rows)
        const data = TableOnOperators_RowData
        //console.log(data)
        let dates_where_str = ''
        if (rows) {
            if (rows.length > 0) {
                dates_where_str = `where date in  ${QUERYS.fenceArr2(rows)}  `
            }
        }
        const _on_opertors_data = AlaSQL(`select 
        crm_operator_name, count(*) count_alanysis_total,
        sum(crm_audio_duration) crm_audio_duration_total,
        avg(grades_total) avg_grades_total
        from ?
        ${dates_where_str}
        group by crm_operator_name
        `, [data])
        setTableOnOperators_FilteredRowData(_on_opertors_data)

        const detaled_grades_data = TableDetailedGrades_RowData
        const _detaled_grades_data = AlaSQL(`select *
        from ?
        ${dates_where_str}
        
        `, [detaled_grades_data])
        setTableDetailedGrades_FilteredRowData(_detaled_grades_data)
        TableDetailedGrades_SizeToFit()
        //console.log(data)
    }



    // Taблица по сотрудникам
    const TableOnOperators_SizeToFit = useCallback(() => {
        if (TableOnOperators_GridRef) {
            if (TableOnOperators_GridRef.current) {
                if (TableOnOperators_GridRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        TableOnOperators_GridRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);
    function TableOnOperators_GetData(props) {
        //setMainTableSwitchShowExpiredDisabled(true)
        //setMainTableSwitchShowSentimentDisabled(true)
        fetch(api.urlFetch.URLs.bd_url_read, {
            //mode: 'no-cors',
            method: 'POST',
            headers: api.urlFetch.URLs.bd_headers,
            body: QUERYS.TableOnOperators_GetQuery(props)
        })
            .then((resp_json) => {
                if (!resp_json.ok) {
                    throw new Error(`Network response was not ok: ${resp_json.status}`);
                }
                return resp_json.json();
            })
            .then((resp) => {
                //console.log(resp)
                if (!resp.data) {
                    //console.error('!resp.data');
                } else {
                    if (resp.data.length === 0) {
                        //console.log('resp.data.length === 0');
                        setTableDetailedGrades_RowData([]);
                        setTableDetailedGrades_FilteredRowData([])
                        setTableOnOperators_RowData([])

                    } else {
                        const data = resp.data;
                        //console.log(data);

                        data.map(row => {
                            const crm_audio_duration = row.crm_audio_duration;
                            row.crm_audio_duration = Number(row.crm_audio_duration);
                            //row.crm_audio_duration_str = `${(crm_audio_duration / 60).toFixed(0)} мин`;

                            let preparing_to_sell = -888
                            let establishing_and_maintaining_contact = -888
                            let dialogue_purpose_plan = -888
                            let presentation = -888
                            let price = -888
                            let closing_deal = -888
                            let qualification = -888
                            let instruction_free_lesson = -888
                            let povtor_preimushestv = -888
                            let present_tariff = -888
                            let dojim = -888


                            row.date = (api.Times.unixTimestampToDateTime(Number(row.analysis_unix) * 1000)).split(' ')[0]


                            let deal_id = row.project_name + '__' + row.crm_lead_id
                            row.deal_id = deal_id

                            let tariff_presentation = 0//'N/A'
                            let objection_handling = 0//'N/A'

                            const json_analysis = row.json_analyze
                            let analysis = {}
                            if (json_analysis) {
                                if (JSON.parse(json_analysis)) {
                                    const json_analysis_parse = JSON.parse(json_analysis)
                                    //console.log(json_analysis_parse)

                                    analysis = json_analysis_parse.analysis
                                    if (analysis) {
                                        if (analysis.length > 0) {
                                            analysis.map(row => {
                                                const { stage } = row
                                                const { stage_grade } = row

                                                if (stage === 'Preparing to sell') {
                                                    preparing_to_sell = stage_grade
                                                }

                                                if ([
                                                    'Establishing and maintaining contact',
                                                    'Установление контакта'
                                                ].includes(stage)) {
                                                    establishing_and_maintaining_contact = stage_grade
                                                }

                                                if ([
                                                    'Квалификация'
                                                ].includes(stage)) {
                                                    qualification = stage_grade
                                                }

                                                if (stage === 'Dialogue purpose/plan') {
                                                    dialogue_purpose_plan = stage_grade
                                                }


                                                if ([
                                                    'Презентация продукта с выгодой для клиента',
                                                    'Презентация',
                                                    'Presentation'
                                                ].includes(stage)) {
                                                    presentation = stage_grade
                                                }

                                                if ([
                                                    "Инструкция бесплатное занятие"
                                                ].includes(stage)) {
                                                    instruction_free_lesson = stage_grade
                                                }

                                                if ([
                                                    "Повторение преимуществ"
                                                ].includes(stage)) {
                                                    povtor_preimushestv = stage_grade
                                                }



                                                if (stage === 'Price') {
                                                    price = stage_grade
                                                }

                                                if ([
                                                    'Дожим до продажи',
                                                    'Closing Deal'
                                                ].includes(stage)) {
                                                    closing_deal = stage_grade
                                                }
                                                if ([
                                                    'Дожим',
                                                ].includes(stage)) {
                                                    dojim = stage_grade
                                                }

                                                if ([
                                                    'Презентация тарифов',
                                                ].includes(stage)) {
                                                    present_tariff = stage_grade
                                                }

                                                if ([
                                                    'Отработка возражений',
                                                ].includes(stage)) {
                                                    objection_handling = stage_grade
                                                }



                                            })

                                        }
                                    }

                                }
                            }

                            //_table_1_data.push({
                            //key: rowData['Оператор'] + "_" + rowData['Дата создания'],
                            //date: rowData['Дата создания'],
                            //analysis: analysis,
                            //crm_operator_name: rowData['Оператор'].replace(' (amoCRM)',''),
                            //bot_name: rowData['Бот анализатор'],
                            function getGrade(grade) {
                                if (grade === -888) return 0
                                return grade
                            }
                            row.grades = {
                                preparing_to_sell: preparing_to_sell,
                                qualification: qualification,
                                establishing_and_maintaining_contact: establishing_and_maintaining_contact,
                                dialogue_purpose_plan: dialogue_purpose_plan,
                                presentation: presentation,
                                price: price,
                                closing_deal: closing_deal,
                                tariff_presentation: tariff_presentation,
                                objection_handling: objection_handling,
                                instruction_free_lesson: instruction_free_lesson,
                                povtor_preimushestv: povtor_preimushestv,
                                present_tariff: present_tariff,
                                dojim: dojim,

                                total: getGrade(preparing_to_sell)
                                    + getGrade(qualification)
                                    + getGrade(establishing_and_maintaining_contact)
                                    + getGrade(dialogue_purpose_plan)
                                    + getGrade(presentation)
                                    + getGrade(price)
                                    + getGrade(closing_deal)
                                    + getGrade(tariff_presentation)
                                    + getGrade(objection_handling)
                                    + getGrade(instruction_free_lesson)
                                    + getGrade(povtor_preimushestv)
                                    + getGrade(present_tariff)
                                    + getGrade(dojim)



                            }
                            row.grades_total = row.grades.total

                        });


                        //TableDetailedGradeSizeToFit()
                        TableDetailedGrades_setData({ data: data })


                        const _on_operators_data = AlaSQL(`select 
                        crm_operator_name, count(*) count_alanysis_total,

                        sum(crm_audio_duration) crm_audio_duration_total,
                        avg(grades_total) avg_grades_total
                        
                        
                        from ?
                        group by crm_operator_name
                        `, [data])

                        //setTableOnOperators_RowData(_on_operators_data)
                        TableOnOperators_setData({ data: data, display_data: _on_operators_data })
                        TableOnOperators_SizeToFit()
                    }
                }
            })
            .catch((error) => {
                console.error(`Fetch error: ${error}`);
            });
    }
    const TableOnOperators_GridReady = useCallback((params) => {
        TableOnOperators_GetData({ paramsFilter: ParamsFilter, userData: userData })
    }, []);
    function TableOnOperators_SelectRows(rows) {
        //setTableDetailedGrades_SelectedOperators(rows)
        const data = TableDetailedGrades_RowData
        let operators_where_str = ''
        if (rows) {
            if (rows.length > 0) {
                operators_where_str = `where crm_operator_name in  ${QUERYS.fenceArr2(rows)}  `
                //console.log(operators_where_str)
            }
        }
        const _detaled_grades_data = AlaSQL(`select * from ? 
        ${operators_where_str}
        `, [data])
        setTableDetailedGrades_FilteredRowData(_detaled_grades_data)
        //console.log(data)
    }
    function TableOnOperators_setData(props) {
        setTableOnOperators_RowData(props.data);
        setTableOnOperators_FilteredRowData(props.display_data)
    }

    const CustomHeader = (params) => {
        return (
            <div>
                Первая<br />
                Строка
            </div>
        );
    };


    // Таблица по оценкам
    // Таблица оценок
    const TableDetailedGrades_ColumnDefs = useMemo(() => [
        {
            headerName: 'Дата',
            field: 'analysis_unix',
            sortable: true,
            //comparator: api.Times.dateComparator
            cellRenderer: (data) => {
                const unix = data.value
                return api.Times.unixTimestampToDateTime(unix * 1000)
            }
        },
        {
            headerName: 'Бот',
            field: 'bot',
            sortable: true
        },
        {
            headerName: 'Сделка',
            field: 'deal_id',
            cellRenderer: (data) => {
                const data0 = data.value
                const s = data0.split('__')
                let lead_id = s[1]
                let project_name = s[0] ? s[0] : ""
                let deal_url = ""
                if (project_name === 'tutgood') {
                    deal_url = `https://tutgood.amocrm.ru/leads/detail/${lead_id}`
                }
                if (project_name === 'likecenter') {
                    deal_url = `https://likebusiness.amocrm.ru/leads/detail/${lead_id}`
                }

                return <a href={deal_url} target="_blank">{lead_id}</a>

            },
            width: 130,
        },
        {
            headerName: 'Сотрудник',
            field: 'crm_operator_name',
            sortable: true
        },
        {
            headerName: 'Длительность',
            field: 'crm_audio_duration',
            sortable: true,
            cellRenderer: (data) => {
                return api.Times.genOutTime({ seconds: data.value })
            },
            width: 130,
        },
        {
            headerName: 'Баллы по этапам',
            suppressStickyLabel: true,
            openByDefault: false,
            sortable: true,
            children: [
                {
                    headerName: 'Всего',
                    field: 'grades.total',
                    sortable: true,
                    //columnGroupShow: 'closed',
                    width: 110,

                },
                {
                    headerName: 'Подготовка к продаже',
                    field: 'grades.preparing_to_sell',
                    sortable: true,
                    columnGroupShow: 'open',
                    headerComponentFramework: CustomHeader,
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                },

                {
                    headerName: 'Установление контакта',
                    field: 'grades.establishing_and_maintaining_contact',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }


                },

                {
                    headerName: 'Квалификация',
                    field: 'grades.qualification',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                },
                {
                    headerName: 'Повторение преимеществ',
                    field: 'grades.povtor_preimushestv',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                },
                {
                    headerName: 'Презентация тарифов',
                    hederTooltip: 'Презентация тарифов',
                    field: 'grades.present_tariff',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                },
                {
                    headerName: 'Отработка возражений',
                    hederTooltip: 'Отработка возражений',
                    field: 'grades.objection_handling',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                },
                {
                    headerName: 'Дожим',
                    hederTooltip: 'Дожим',
                    field: 'grades.dojim',
                    sortable: true,
                    columnGroupShow: 'open',
                    width: 110,
                    cellRenderer: (data) => { if (data.value === -888) return ''; return data.value }
                }
            ]
        },
        {
            headerName: 'Отчеты',
            field: 'bid',
            pinned: 'right',
            sortable: true,
            width: 135,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    showModal(field)
                    //alert(`${field} was clicked`);
                },
            },
        },
    ], []);
    function TableDetailedGrades_setData(props) {
        //console.log(props)
        setTableDetailedGrades_RowData(props.data);
        setTableDetailedGrades_FilteredRowData(props.data)
    }
    const TableDetailedGrades_SizeToFit = useCallback(() => {
        if (TableDetailedGrades_GridRef) {
            if (TableDetailedGrades_GridRef.current) {
                if (TableDetailedGrades_GridRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        TableDetailedGrades_GridRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (visible) {
            setLoading(true);

            fetchContent('https://report.aiwin.app/showreport?id=' + bid)
                .then((data) => {
                    //console.log(data)
                    setContent(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Ошибка при загрузке данных:', error);
                    setLoading(false);
                });
        }
    }, [visible]);

    return (<>

        {/* <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>App</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <Text style={{
            fontWeight: 'bold',
            fontFamily: 'monospace',
            fontSize: 35,
            margin: 15,
            color: '#2B3674'
        }}>Основной дашборд</Text > */}

        <Row>

            <Card size='small'
                style={{
                    width: '100%',
                    //margin: 5
                }}
            >
                <Row>
                    <Col span={6}>
                        Проект
                    </Col>
                    <Col span={6}>
                        <Select
                            mode="multiple"
                            tagRender={COLUMNS.tagRender}
                            defaultValue={[]}
                            style={{
                                width: '100%',
                            }}
                            value={ParamsFilter.selectedProjectNames ? ParamsFilter.selectedProjectNames : []}
                            options={ProjectNames}
                            onChange={onChangeProjectNames}
                        />
                    </Col>
                    <Col span={6}>
                        <CustomRangePicker
                            defaultFrom={ParamsFilter.timeData.work.date_from}
                            defaultTo={ParamsFilter.timeData.work.date_to}
                            valueFrom={RangePickerNewValue ? (
                                RangePickerNewValue.from ? RangePickerNewValue.from :
                                    ParamsFilter.timeData.work.date_from
                            ) : ParamsFilter.timeData.work.date_from}
                            valueTo={RangePickerNewValue ? (
                                RangePickerNewValue.to ? RangePickerNewValue.to :
                                    ParamsFilter.timeData.work.date_to
                            ) : ParamsFilter.timeData.work.date_to}
                            onChange={onChangeRangePicker}
                        ></CustomRangePicker>
                    </Col>
                    <Col span={6}>
                        <AntDateSelectOptions
                            defaultValue={ParamsFilter.timeData.value}
                            options={DateSelectOptionsRaw}
                            onChange={onChangeAntDateSelectOptions}
                            value={DateSelectOptionsValue}
                        ></AntDateSelectOptions>
                    </Col>
                </Row>
            </Card>


        </Row>

        <Row

        >
            <Col span={12}
                style={{
                    paddingRight: 6,
                    paddingBottom: 12,
                    paddingTop: 12
                }}
            >

                <Card title='По дням'
                    style={{
                        width: '100%',
                        //margin: 5
                    }}

                    size='small'>
                    Общая статистика по дням
                    <div style={containerStyle}>
                        <div style={{ height: 540 }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={TableOnDays_GridRef}
                                rowData={TableOnDays_RowData}
                                columnDefs={COLUMNS.TableOnDays_ColumnDefs}
                                defaultColDef={COLUMNS.defaultColDef}
                                //onGridReady={onGridReadyTableOnDays}
                                onGridReady={(e) => { TableOnDays_GridReady(e) }}

                                pagination={true}
                                paginationPageSize={10}
                                onRowClicked={(e) => {
                                    //const data = e.data
                                    //onRowClickTableOnDays(data)
                                    const selectedData = e.api.getSelectedRows();
                                    //console.log(selectedData)
                                    const selectedDates = AlaSQL('select distinct call_date call_date from ?', [selectedData])
                                    if (selectedDates) {
                                        if (selectedDates.length > 0) {
                                            const transformedArray = selectedDates.map(obj => obj.call_date);
                                            //console.log(transformedArray)
                                            TableOnDays_SelectRows(transformedArray)
                                        } else {
                                            //console.log([])
                                            TableOnDays_SelectRows([])
                                        }
                                    }
                                }}
                                rowSelection={'multiple'}
                            />
                        </div>
                    </div>
                </Card>

            </Col>

            <Col span={12}
                style={{
                    paddingLeft: 6,
                    paddingBottom: 12,
                    paddingTop: 12

                }}
            >
                <Card title='По сотрудникам' style={{
                    width: '100%',
                    //margin: 5,

                }} size="small">
                    Готовые анализы по сотрудникам
                    <div style={containerStyle}>
                        <div style={{ height: 540 }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={TableOnOperators_GridRef}
                                rowData={TableOnOperators_FilteredRowData}
                                columnDefs={COLUMNS.TableOnOperators_ColumnDefs}
                                defaultColDef={COLUMNS.defaultColDef}

                                pagination={true}
                                paginationPageSize={10}
                                multiSortKey={'ctrl'}
                                animateRows={true}
                                onGridReady={(e) => { TableOnOperators_GridReady(e) }}
                                onRowClicked={(e) => {
                                    //const data = e.data
                                    //onRowClickTableOnDays(data)
                                    //console.log(e)



                                    const selectedData = e.api.getSelectedRows();
                                    const selectedOperators = AlaSQL('select distinct crm_operator_name crm_operator_name from ?', [selectedData])
                                    //setTableOnOperatorsSelectedOperators(selectedOperators[0])

                                    if (selectedOperators) {
                                        if (selectedOperators.length > 0) {
                                            const transformedArray = selectedOperators.map(obj => obj.crm_operator_name);
                                            //console.log(transformedArray)
                                            TableOnOperators_SelectRows(transformedArray)
                                        } else {
                                            //console.log([])
                                            TableOnOperators_SelectRows([])
                                        }
                                    }
                                }}
                                rowSelection={'multiple'}

                            />
                        </div>
                    </div>
                </Card>

            </Col>


            <Card title='Подробные анализы' style={{
                width: '100%',
                //margin: 5
            }} size="small">
                Анализы с оценками
                <div style={containerStyle}>
                    <div style={{ height: 580 }} className="ag-theme-alpine">
                        <AgGridReact
                            ref={TableDetailedGrades_GridRef}
                            rowData={TableDetailedGrades_FilteredRowData}
                            columnDefs={TableDetailedGrades_ColumnDefs}
                            defaultColDef={COLUMNS.defaultColDef}
                            //onGridReady={TableDetailedGrades_OnGridReady}
                            pagination={true}
                            paginationPageSize={10}

                        />
                    </div>
                </div>
            </Card>
        </Row>

        <Modal
            width="95%"
            bodyStyle={{
                top: '0.5%',
                height: `calc(${windowSize.height * 0.75}px - 48px)`,
                overflow: 'auto',
                padding: 0, // убрать паддинг
            }} // 95% от высоты окна и auto-scroll
            title="Анализ и диалог"
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
        >
            {loading ? <Spin /> : <div dangerouslySetInnerHTML={{ __html: content }} />}
        </Modal>
    </>)
}

export default DashboardStatic_v2