import * as Transcripts from './transcripts'
export const CONFIG = {
    api_url: 'https://us-central1-spy2money.cloudfunctions.net/aiwin/1/',
    api_auth: 'Bearer Akm2eucYXflDuHFaUfbSaY9Nw93',
    API_METHODS: {
        GET_TRANSCRIPTS_BY_UID: 'getTranscriptsByUid'
    }
}

export {
    Transcripts
}