import { doc, setDoc, updateDoc, getFirestore, collection, addDoc, where, query, getDoc, getDocs } from "@firebase/firestore"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    getAdditionalUserInfo,
    signOut
} from '@firebase/auth';
import moment from 'moment';
import * as MAIN_CONFIG from '../MainConfig'

const firebaseConfig = {
    apiKey: "AIzaSyD9l4V8ETbpqCC4avB89QS7KmccEw7VacM",
    authDomain: "aiwin-158aa.firebaseapp.com",
    projectId: "aiwin-158aa",
    storageBucket: "aiwin-158aa.appspot.com",
    messagingSenderId: "436489526576",
    appId: "1:436489526576:web:1a322886dcabc1ab9d3cf5",
    measurementId: "G-JF86XDGR4V"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore();
//const provider = new GoogleAuthProvider();
//provider.setCustomParameters({ prompt: 'select_account' });

const auth = getAuth();
const provider = new GoogleAuthProvider();

export const getConfigs = async () => {
    const translationsRef = collection(db, "configs");
    const q = query(translationsRef, where("id", "!=", ' '));
    let r = {}
    try {
        const querySnapshot = await getDocs(q);
        let outData = {}
        querySnapshot.forEach(async (d) => {
            const docData = d.data()
            const id = docData.id
            outData[id] = docData
        });
        r = {
            status: 'ok',
            data: outData
        }
    } catch (err) {
        r = {
            status: 'error',
            message: err.message
        }
    }
    return r
}

/*export const genCustomToken = (uid) => {
    let out = { status: 'error', token: '' }
    getAuth().createCustomToken(uid)
        .then((customToken) => {
            out.status = 'ok'
            out.token = customToken
            // Send token back to client
        })
        .catch((error) => {
            out.message = ['Error creating custom token:', error]
        })
    return out
}*/

export function synchWithExtension(props) {
    //console.log('aiwin function synchWithExtension')
    window.postMessage({type: 'FROM_REACT_APP', data: 'yourData'}, '*');
    if (props) {
        window.postMessage({
            //pointer: 'popup',
            type: 'aiwin_identification',
            identification: {
                uid: props.uid,
                email: props.email,
                displayName: props.displayName
            }
        },'*');
        // console.log('props ok',  {
        //     uid: props.uid,
        //     email: props.email,
        //     displayName: props.displayName
        // })
    }
}

export const signInWithGoogle = async () => {
    const nowUtc = Number(moment().utc().unix())
    var uid, email, displayName = ''
    try {
        const res = await signInWithPopup(auth, provider);
        const user = res.user;
        uid = user.uid; email = user.email; displayName = user.displayName
        const userRef = collection(db, "users");
        const result = await getDocs(query(userRef, where("uid", "==", uid)));
        if (result.empty) {
            let transcripts = []
            const default_configs = await getConfigs()
            try {
                if (default_configs.status === 'ok') {
                    const configs = default_configs.data
                    //console.log(configs)
                    if (configs.tutorial) {
                        transcripts.push(configs.tutorial)
                    }
                }
                await addDoc(collection(db, "users"), {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                    created: nowUtc,
                    transcripts: transcripts
                });
            } catch (e) {
                console.log(e)
            }
        } else {

        }
        //synchWithExtension({ uid: uid, email: email, displayName: displayName })
    } catch (err) {
        console.log(err.message)
    }
};

export const getUserDocById = async (uid) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "==", uid));
    let r = {}
    try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (d) => {
            r = {
                status: 'ok',
                id: d.id,
                data: d.data()
            }
        });
    } catch (err) {
        r = {
            status: 'ok',
            message: err.message
        }
    }
    return r
}

export default firebase;