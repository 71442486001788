import * as urlFetch from './urlFetch'
import * as manyChat from './manyChat'
import * as localStore from './localStore'
import * as googleCloud from './googleCloud'
import * as appTheme from './theme'
import * as antDesign from './antDesign'
import * as Aiwin from './aiwin'
import * as Times from './times'

export default {
    urlFetch, manyChat, localStore, googleCloud, appTheme, antDesign, Aiwin, Times
}