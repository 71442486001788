export const main_language = 'ru'

export const main_strings = {
    'ru': {
        login_page_str_1: 'Войти в Aiwin',
        login_page_str_2: 'Войдите, чтобы сохранять и читать стенограммы.',
        login_page_str_3: 'Войти с помощью Google',
        transcripts_page_menu_item: 'Транскрипции',
        transcripts_page_title: 'Транскрипции',
        transcript_page_title: 'Транскрипция',
        dashboard_page_menu_item: 'Дашбоард',
        statistics_page_menu_item: 'Статистика'

    },
    'en': {
        login_page_str_1: 'Sing in Aiwin',
        login_page_str_2: 'Log in to save and read transcripts.',
        login_page_str_3: 'Sign in with Google'
    },
    'dashboard':{
        'ru': {
            'statistics_calls_total_time': 'Общее время звонков',
            'statistics_calls_len': 'Кол-во звонков'
        }
    }
}

export function getMainStrings() {
    return main_strings[main_language]
}