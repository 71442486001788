import React, { useState } from 'react';
import { Outlet, useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import { Breadcrumb, Button, Layout, Menu, theme, Image, Row, ConfigProvider } from 'antd';
import {
    UploadOutlined, UserOutlined, VideoCameraOutlined, DesktopOutlined, TeamOutlined, FileOutlined, PieChartOutlined
} from '@ant-design/icons';
import { getAuth, getUserDocById } from '@firebase/auth';

import logo from '../src/img/logo.svg'
import DialogContent from './pages/Transcripts/DialogContent';

import * as MAIN_CONFIG from './MainConfig'
import Trancripts from './pages/Transcripts';
import Dashboard from './pages/Dashboard';
import DashboardStatic from './pages/DashboardStatic';
import DashboardStatic_v2 from './pages/DashBoardStatic_v2';
import StatisticsPage from './pages/Statistics';
import api from './api'

const auth = getAuth();


const onclickSignOut = () => {
    auth.signOut()
    return (<Navigate to="/login"></Navigate>)
}


const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const pagePosition = 'aiwin-main'
const menuDefaultSelected = 'dashboard'

const App = ({ user, userData }) => {

    const main_strs = MAIN_CONFIG.getMainStrings()
    //const [collapsed, setCollapsed] = useState(false);
    const { token: { colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();
    const menuSelectedItem = api.localStore.getFromLocalStorage(pagePosition + '-menu-selected')
        ? api.localStore.getFromLocalStorage(pagePosition + '-menu-selected') : menuDefaultSelected
    const items = [
        api.antDesign.getItem(main_strs.dashboard_page_menu_item, 'dashboard'),
        api.antDesign.getItem(main_strs.statistics_page_menu_item, 'statistics'),
        //api.antDesign.getItem(main_strs.transcripts_page_menu_item, 'transcripts'),
        //api.antDesign.getItem('Test', 'transcripts2')
    ];

    const [current, setCurrent] = useState(menuSelectedItem);

    const onClickMenuItem = (e) => {
        const selected = e.key
        api.localStore.saveToLocalStorage(pagePosition + '-menu-selected', selected)
        setCurrent(selected)
        navigate(`/app/${selected}`)
    };


    const ProtectedRoute = ({ user, userData, children }) => {
        if (!user) {
            return <>Не аторизованы.</>;
        } else {
            if (!userData.role) {
                return <>Вы авторизованы. Запросите доступ к проектам у администратора.</>;
            } else {
                if (userData.role != 'user' && userData.role != 'admin') {
                    return <>Неизвестный доступ.</>;
                }
            }

            return children;
        };
    }

    return (

        <ConfigProvider
            theme={{
                algorithm: theme.defaultAlgorithm,
            }}
        >


            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                <Sider
                    style={{background: 'white'}}

                >


                    <Row justify='center' style={{
                        paddingBottom: 15,
                        margin: '16px 0',
                    }}
                    >
                        <Image

                            preview={false}
                            width={106}
                            src={logo}
                        />
                    </Row>

                    <Menu
                        style={{

                        }}
                        theme="light"

                        mode="inline"


                        defaultSelectedKeys={[menuDefaultSelected]}
                        selectedKeys={[current]}
                        onClick={onClickMenuItem}
                        items={items}
                    />


                    <Row justify='center'
                        style={{
                            paddingBottom: 15,
                            margin: '16px 0',
                        }}
                    >
                        <Button
                            onClick={onclickSignOut}
                        >
                            SignOut
                        </Button>
                    </Row>


                </Sider>


                <Layout
                style={{
                   
                    background:  '#F4F7FE',

                }}
                >

                    <Content
                        style={{
                            margin: '24px 16px 0',
                            overflow: 'initial',
                          }}
                    >

                        <div
                            
                        >
                            <Routes>


                                <Route path="dashboard" element={
                                    <ProtectedRoute user={user} userData={userData}> <DashboardStatic_v2 user={user} userData={userData} />
                                    </ProtectedRoute>} >

                                    <Route path=":type" >
                                    </Route>
                                </Route>


                                <Route path="statistics" element={
                                    <ProtectedRoute user={user} userData={userData}> <StatisticsPage user={user} userData={userData} />
                                    </ProtectedRoute>} >

                                    <Route path=":type" >
                                    </Route>
                                </Route>
                            </Routes>


                        </div>

                    </Content>





                </Layout>
            </Layout>

        </ConfigProvider>
    );
};


export default App;

/*
<Footer
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        Ant Design ©2023 Created by Ant UED
                    </Footer>
*/