import api from '../../api'


export function updateUser(subscriber_id) {
    const url = "https://us-central1-spy2money.cloudfunctions.net/api?token=32AkmeucYXflDuH131P23ddGs&method=updateUser&user_id=" + subscriber_id;
    //const headers = config.connect.headers
    const r = api.urlFetch.urlAxios({
        method: 'get',
        url: url,
        //headers: headers
    })
    return r
}